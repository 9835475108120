import { Spinner, Box } from '@chakra-ui/react';
import { AppSpinnerProps } from '../types/commonTypes';

function AppSpinner({ isTransparent, loaderPosition }: AppSpinnerProps) {
  return (
    <Box
      left="0"
      top="0"
      w="100%"
      h="100vh"
      opacity={isTransparent ? '0.7' : 'unset'}
      backgroundColor={isTransparent ? 'black' : 'unset'}
      zIndex="999999"
      position={loaderPosition}
    >
      <Spinner
        color="positive"
        position="absolute"
        left="50%"
        top="50%"
        zIndex="999999"
        size="lg"
      />
    </Box>
  );
}
export default AppSpinner;
