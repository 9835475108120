import { ApiCallMethods } from '../constants/api';
import { PermissionType } from '../constants/common';
import { FabricPermissionPayload } from '../types/permission';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';
import { getFabric, getSession } from '../utils/persist';

export const getUserPermissions = (user: string) => {
  const url = `${getBaseURL()}/_api/user/${user}/database?full=true`;
  return callApi(ApiCallMethods.Get, url);
};

export const updateUserFabricPermission = (fabricPermissionPayload: FabricPermissionPayload) => {
  const { fabricName, permission, userInfo } = fabricPermissionPayload;
  const { tenant, user } = userInfo!;
  const shouldUseDefault = permission.grant === PermissionType.USE_DEFAULT;
  const url = `${getBaseURL()}/_api/user/${tenant}.${user}/database/${tenant}.${fabricName}`;
  return callApi(shouldUseDefault ? ApiCallMethods.Delete : ApiCallMethods.Put, url, permission);
};

export const updateUserCollectionPermission = (
  fabricPermissionPayload: FabricPermissionPayload,
) => {
  const { fabricName, entityName, permission, userInfo } = fabricPermissionPayload;
  const { tenant, user } = userInfo!;
  const shouldUseDefault = permission.grant === PermissionType.USE_DEFAULT;
  const url = `${getBaseURL()}/_api/user/${tenant}.${user}/database/${tenant}.${fabricName}/collection/${entityName}`;
  return callApi(shouldUseDefault ? ApiCallMethods.Delete : ApiCallMethods.Put, url, permission);
};

export const updateUserStreamPermission = (fabricPermissionPayload: FabricPermissionPayload) => {
  const { fabricName, entityName, permission, userInfo } = fabricPermissionPayload;
  const { tenant, user } = userInfo!;
  const shouldUseDefault = permission.grant === PermissionType.USE_DEFAULT;
  const url = `${getBaseURL()}/_api/user/${tenant}.${user}/database/${tenant}.${fabricName}/stream/${entityName}`;
  return callApi(shouldUseDefault ? ApiCallMethods.Delete : ApiCallMethods.Put, url, permission);
};

export const getApiKeyPermissions = (apiKey: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/key/${apiKey}/database?full=true`;
  return callApi(ApiCallMethods.Get, url);
};

export const updateApikeyFabricPermission = (fabricPermissionPayload: FabricPermissionPayload) => {
  const { fabricName, permission, apiKeyName } = fabricPermissionPayload;
  const shouldUseDefault = permission.grant === PermissionType.USE_DEFAULT;
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/key/${apiKeyName}/database/${
    getSession().tenant
  }.${fabricName}`;
  return callApi(shouldUseDefault ? ApiCallMethods.Delete : ApiCallMethods.Put, url, permission);
};

export const updateApikeyCollectionPermission = (
  fabricPermissionPayload: FabricPermissionPayload,
) => {
  const { fabricName, permission, apiKeyName, entityName } = fabricPermissionPayload;
  const shouldUseDefault = permission.grant === PermissionType.USE_DEFAULT;
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/key/${apiKeyName}/database/${fabricName}/collection/${entityName}`;
  return callApi(shouldUseDefault ? ApiCallMethods.Delete : ApiCallMethods.Put, url, permission);
};

export const updateApikeyStreamPermission = (fabricPermissionPayload: FabricPermissionPayload) => {
  const { fabricName, permission, apiKeyName, entityName } = fabricPermissionPayload;
  const shouldUseDefault = permission.grant === PermissionType.USE_DEFAULT;
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/key/${apiKeyName}/database/${fabricName}/stream/${entityName}`;
  return callApi(shouldUseDefault ? ApiCallMethods.Delete : ApiCallMethods.Put, url, permission);
};

export const getApiKeyBillingPermissions = (apiKey: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/key/${apiKey}/billing?`;
  return callApi(ApiCallMethods.Get, url);
};

export const updateApiKeyBillingPermission = (fabricPermissionPayload: FabricPermissionPayload) => {
  const { apiKeyName, permission } = fabricPermissionPayload;
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/key/${apiKeyName}/billing`;
  return callApi(ApiCallMethods.Put, url, permission);
};

export const getUserBillingPermissions = (user: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/user/${user}/billing`;
  return callApi(ApiCallMethods.Get, url);
};

export const updateUserBillingPermission = (fabricPermissionPayload: FabricPermissionPayload) => {
  const { userInfo, permission } = fabricPermissionPayload;
  const { tenant, user } = userInfo!;
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/user/${tenant}.${user}/billing`;
  return callApi(ApiCallMethods.Put, url, permission);
};

export const getBillingPermissions = () => {
  const { tenant, username } = getSession();
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/user/${tenant}.${username}/billing`;
  return callApi(ApiCallMethods.Get, url);
};
