import { Link as RouteLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  BoxProps,
  Divider,
  Flex,
  Image,
  CloseButton,
  Link,
  Text,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import React, { useEffect, useRef, useState } from 'react';
import { ProtectedAppRoutes, RouteDetail, RouteLabels } from 'routing-details';
import Dropdown from '../Dropdown';
import { ElementMappings } from '../../pages/AllRouteDetails';
import NavItem from './NavItem';
import { getLocalEdgeLocation, getLocations, getTenantEdgeLocations } from '../../apis/locations';
import { DropDownOptions } from '../../types/commonTypes';
import { DcInfo } from '../../types/location';
import { getFabric, getRedirectURL, getSession, setFabric } from '../../utils/persist';
import { useConfig } from '../../providers/configContext';
import { getCurrentUserFabrics, getFabricInfo } from '../../apis/fabric';
import { useToast } from '../../providers/toastContext';
import { addEllipsis, getLogoImageUrl, setAutoLoginCookies, showBanner } from '../../utils/helper';
import CaretUp from '../../assets/icons/CaretUp';
import { userDetails } from '../../apis/account';
import { NestedRouteDetails } from '../../types/provider';
import Data from '../../assets/icons/Data';
import Globe from '../../assets/icons/Globe';
import AngleLeft from '../../assets/icons/AngleLeft';
import ChevronDown from '../../assets/icons/ChevronDown';
import Compute from '../../assets/icons/Compute';
import Access from '../../assets/icons/Access';

function SidebarContent({ onClose }: BoxProps & { onClose: () => void }) {
  const { throwAppError } = useToast();
  const { tenant, username } = getSession();
  const fabric = getFabric();
  const navigate = useNavigate();
  const { userConfiguration, logout, tenantDetails, theme, isAvantGardeFlowEnabled } = useConfig();
  const selectedFabricRef = useRef('');

  const [fabrics, setFabrics] = useState<DropDownOptions[]>([]);
  const [locations, setLocations] = useState<DropDownOptions[]>([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [dashboardRoute, setDashboardRoute] = useState<RouteDetail>();
  const [nestedRoutes, setNestedRoutes] = useState<NestedRouteDetails[]>();
  const [normalRoutes, setNormalRoutes] = useState<RouteDetail[]>();
  const location = useLocation();

  useEffect(() => {
    const nestedRouteData: NestedRouteDetails[] = [
      {
        label: 'Data',
        routes: [],
        icon: Data,
      },
      {
        label: 'Compute',
        routes: [],
        icon: Compute,
      },
      {
        label: 'Access',
        routes: [],
        icon: Access,
      },
      {
        label: 'Network',
        routes: [],
        icon: Globe,
      },
    ];
    const normalRoutesData: RouteDetail[] = [];

    userConfiguration.forEach((item, index) => {
      switch (item.label) {
        case RouteLabels.Dashboard:
          setDashboardRoute(userConfiguration[index]);
          break;
        case RouteLabels.Collections:
        case RouteLabels.Streams:
        case RouteLabels.Graphs:
        case RouteLabels.SearchViews:
          nestedRouteData[0].routes.push(userConfiguration[index]);
          break;
        case RouteLabels.Functions:
        case RouteLabels.QueryWorkers:
        case RouteLabels.StreamWorkers:
          nestedRouteData[1].routes.push(userConfiguration[index]);
          break;
        case RouteLabels.Users:
        case RouteLabels.ApiKeys:
          nestedRouteData[2].routes.push(userConfiguration[index]);
          break;
        case RouteLabels.GeoFabrics:
        case RouteLabels.Locations:
          nestedRouteData[3].routes.push(userConfiguration[index]);
          break;
        default:
          normalRoutesData.push(userConfiguration[index]);
          break;
      }
      const modifyNestedRoutes = nestedRouteData.filter(
        (nestedRoute) => nestedRoute.routes.length && nestedRoute,
      );
      setNestedRoutes(modifyNestedRoutes);
      setNormalRoutes(normalRoutesData);
    });
  }, [userConfiguration]);

  const { data: userInfo } = useQuery('userDetails', () => userDetails(`${tenant}.${username}`), {
    onError: throwAppError,
  });

  const { refetch: getFabricRefetch } = useQuery('fabrics', () => getCurrentUserFabrics(), {
    onSuccess: (res) => {
      const data = Object.keys(res.data.result).map((item) => ({ label: item, value: item }));
      if (data.length) {
        setFabrics(data);
      }
    },
  });

  const { data: locationQueryData } = useQuery('locations', () => getLocations(fabric), {
    enabled: Boolean(fabric),
    onError: throwAppError,
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      refetchTenantLocationsQuery();
    },
  });

  useQuery('getLocalEdgeLocation', getLocalEdgeLocation, {
    onError: throwAppError,
    onSuccess: (res) => {
      const {
        locationInfo: { city, countrycode },
        name,
      } = res.data;
      setSelectedLocation(name);
      document.title = `${city}, ${countrycode}`;
    },
  });

  const { refetch: refetchTenantLocationsQuery, data: tenantLocationDetails } = useQuery(
    'tenantLocations',
    () => getTenantEdgeLocations(tenant),
    {
      enabled: false,
      onSuccess: (res) => {
        const data: DropDownOptions[] = [];
        const { dcInfo } = res.data[0];

        if (locationQueryData && dcInfo) {
          const { clusters }: { clusters: string[] } = locationQueryData.data;
          dcInfo.forEach((item: DcInfo) => {
            // eslint-disable-next-line no-underscore-dangle
            if (clusters && clusters.includes(item.locationInfo._key)) {
              const { city, countryname } = item.locationInfo;
              data.push({ label: `${city}, ${countryname}`, value: item.name });
            }
          });
        }

        if (data.length) {
          setLocations(data);
        }
      },
    },
  );

  const navigateToSettings = (close: () => void) => {
    close();
    navigate(`/${ProtectedAppRoutes.Users}`, {
      state: { fromSettingsUserDetails: true },
    });
  };

  const { refetch: getFabricInfoRefetch } = useQuery(
    'getFabricDetails',
    () => getFabricInfo(selectedFabricRef.current),
    {
      enabled: false,
      onError: throwAppError,
      onSuccess: (res) => {
        if (!getRedirectURL() && res.data.result.dnsInfo?.global_url) {
          const url = res.data.result.dnsInfo.global_url;
          const { token } = getSession();
          setAutoLoginCookies(token, selectedFabricRef.current, url);
          window.location.replace(`https://${url}`);
        } else {
          window.location.replace(window.location.origin);
        }
      },
    },
  );

  const { data: currentFabricDetails } = useQuery(
    'getFabricInfo',
    () => getFabricInfo(getFabric()),
    {
      onError: (err) => {
        throwAppError(err);
      },
    },
  );

  const handleSelectedFabric = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setFabric(value);
    selectedFabricRef.current = value;
    getFabricInfoRefetch();
  };

  const handleSelectRegion = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const { token } = getSession();
    let url = '';
    setSelectedLocation(event.target.value);
    if (!getRedirectURL() && currentFabricDetails?.data.result?.dnsInfo?.global_url) {
      url =
        currentFabricDetails.data.result?.dnsInfo.regional_urls[
          value.slice(value.indexOf('-') + 1)
        ];
    } else if (tenantLocationDetails?.data[0]) {
      const { dcInfo } = tenantLocationDetails.data[0];
      const { locationInfo }: DcInfo = dcInfo.find(({ name }: DcInfo) => name === value);
      url = locationInfo.url;
    }
    setAutoLoginCookies(token, getFabric(), url);
    window.location.replace(`https://${url}`);
  };

  const getIndividualItems = (itemRouteDetail: RouteDetail) => {
    const {
      route: { path = '', element },
      route,
      label,
      externalLink,
    } = itemRouteDetail;

    const { navIcon } = ElementMappings[element];
    return (
      <Box px="2" key={label}>
        {externalLink ? (
          <Link
            href={externalLink}
            isExternal
            key={path}
            _hover={{
              textDecoration: 'none',
            }}
            data-id="menu"
            data-cy={externalLink}
          >
            <NavItem route={route} label={label} navIcon={navIcon} />
          </Link>
        ) : (
          <Link
            as={RouteLink}
            to={path}
            _hover={{
              textDecoration: 'none',
            }}
            data-id="menu"
            data-cy={path}
            key={path}
          >
            <NavItem route={route} label={label} navIcon={navIcon} />
          </Link>
        )}
      </Box>
    );
  };

  return (
    <Box
      transition="3s ease"
      bg="nav_body_bg_color"
      borderRight="1px"
      borderRightColor="nav_item_border_color"
      h={{ base: 'full', sm: '100%' }}
    >
      <Flex h="16" alignItems="center" px="4" justifyContent="space-between">
        <Image src={getLogoImageUrl(theme)} alt="Macrometa Logo" h="30px" w="144px" />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      {userConfiguration.length > 1 && (
        <>
          <Box overflow="auto" h="85%" pb="15%">
            <Dropdown
              options={fabrics}
              value={fabric}
              onChange={handleSelectedFabric}
              mx="2"
              my="2"
              w="13"
              onClick={() => getFabricRefetch()}
              fontSize="md"
              data-cy="changeFabric"
              borderColor="nav_item_border_color"
              iconColor="nav_item_icon_color"
            />
            <Dropdown
              mx="2"
              my="2"
              w="13"
              size="md"
              options={locations}
              value={selectedLocation}
              onChange={handleSelectRegion}
              fontSize="md"
              data-cy="changeRegion"
              borderColor="nav_item_border_color"
              iconColor="nav_item_icon_color"
            />

            <Box flexDirection="column" data-cy="sideBar">
              <Box px="2">
                {dashboardRoute && dashboardRoute.label && (
                  <Link
                    as={RouteLink}
                    to={dashboardRoute.route.path}
                    _hover={{
                      textDecoration: 'none',
                    }}
                    data-id="menu"
                    data-cy={dashboardRoute.route.path}
                  >
                    <Box
                      borderWidth="1px"
                      borderColor="nav_item_border_color"
                      my="2"
                      borderRadius="base"
                    >
                      <NavItem
                        route={dashboardRoute.route}
                        label={dashboardRoute.label}
                        navIcon={ElementMappings[dashboardRoute.route.element].navIcon}
                      />
                    </Box>
                  </Link>
                )}
              </Box>
              {nestedRoutes && nestedRoutes.length > 0 && (
                <Accordion allowMultiple>
                  {nestedRoutes.map((item) => {
                    const shouldHighlightItem = item.routes.filter(
                      (routeItem) => routeItem.route.path === location.pathname.split('/')[1],
                    ).length;
                    return (
                      <AccordionItem
                        key={item.label}
                        borderColor="nav_item_border_color"
                        px="2"
                        data-cy={item.label}
                      >
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton
                              pl="3"
                              pr="2"
                              py="2.5"
                              h="42"
                              color="nav_item_color"
                              _hover={{
                                color: 'nav_item_hover_color',
                              }}
                              data-group
                              _expanded={{ fontWeight: 'bold' }}
                              fontWeight={shouldHighlightItem ? 'bold' : 'inherit'}
                            >
                              <Icon
                                as={item.icon}
                                opacity="nav_icon_opacity"
                                fontSize="md"
                                mr={3.5}
                                fill={isExpanded ? 'nav_item_color' : 'primary.400'}
                                _groupHover={{
                                  fill: 'nav_item_hover_color',
                                }}
                              />
                              <Box
                                flex="1"
                                textAlign="left"
                                color={shouldHighlightItem ? 'nav_item_active_bg_color' : 'inherit'}
                                fontSize="md"
                                lineHeight="10"
                              >
                                {item.label}
                              </Box>
                              {isExpanded ? (
                                <ChevronDown fill="neutral.500" fontSize="xs" mr="1.5" />
                              ) : (
                                <AngleLeft fill="nav_item_icon_color" fontSize="xs" mr="1.5" />
                              )}
                            </AccordionButton>

                            {item.routes.map((routeDetails, index) => {
                              const {
                                route: { path = '', element },
                                route,
                                label,
                              } = routeDetails;

                              const { navIcon } = ElementMappings[element];
                              return (
                                <AccordionPanel
                                  key={path}
                                  p="0"
                                  mb={index === item.routes.length - 1 ? '2' : 'inherit'}
                                >
                                  <Link
                                    as={RouteLink}
                                    to={path}
                                    _hover={{
                                      textDecoration: 'none',
                                    }}
                                    data-id="menu"
                                    data-cy={path}
                                  >
                                    <NavItem
                                      route={route}
                                      label={label}
                                      navIcon={navIcon}
                                      isInnerItem
                                    />
                                  </Link>
                                </AccordionPanel>
                              );
                            })}
                          </>
                        )}
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              )}
              <Box my="2">
                {normalRoutes &&
                  normalRoutes.length > 1 &&
                  normalRoutes.map((routeDetails) => getIndividualItems(routeDetails))}
              </Box>
            </Box>
          </Box>

          <Popover>
            {(popover) => (
              <>
                <PopoverTrigger>
                  <Box
                    pos="absolute"
                    bottom={showBanner(tenantDetails, isAvantGardeFlowEnabled) ? '10' : '0'}
                    w="full"
                    bg="nav_body_bg_color"
                    borderRight="1px"
                    borderColor="nav_item_border_color"
                    cursor="pointer"
                  >
                    <Divider colorScheme="neutral" />
                    <Flex align="center" h="10%" p="4" justify="space-between">
                      <Flex data-cy="userInfo" align="center">
                        <Avatar h="7" w="7" />
                        <Text fontSize="md" ml="2" color="neutral.600" wordBreak="break-all">
                          {addEllipsis(`${userInfo?.data.email}`, 15)}
                        </Text>
                      </Flex>
                      <CaretUp fill="nav_item_icon_color" fontSize="xs" mr="2" />
                    </Flex>
                  </Box>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent maxW="60">
                    <Flex flexDirection="column" p="3" bg="primary.50">
                      <Button
                        variant="outline"
                        borderRadius="sm"
                        onClick={() => {
                          navigateToSettings(popover.onClose);
                        }}
                      >
                        <Text fontSize="md" lineHeight="8">
                          Settings
                        </Text>
                      </Button>
                      <Button mt="3" borderRadius="sm" onClick={() => logout()}>
                        <Text fontSize="md" lineHeight="8">
                          Logout
                        </Text>
                      </Button>
                    </Flex>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        </>
      )}
    </Box>
  );
}

export default SidebarContent;
