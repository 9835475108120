import { createIcon } from '@chakra-ui/react';

const ApiReference = createIcon({
  displayName: 'ApiReferenceIcon',
  viewBox: '0 0 14 14',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.536.536a.656.656 0 0 1 .928 0l4 4a.656.656 0 0 1 0 .928l-4 4a.656.656 0 0 1-.928-.928L12.072 5 8.536 1.464a.656.656 0 0 1 0-.928ZM5.464.536a.656.656 0 0 1 0 .928L1.928 5l3.536 3.536a.656.656 0 0 1-.928.928l-4-4a.656.656 0 0 1 0-.928l4-4a.656.656 0 0 1 .928 0Z"
    />
  ),
});

export default ApiReference;
