import {
  createStandaloneToast,
  extendTheme,
  theme as chakraTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';

import { colors, macrometaColors, coxColors } from './foundations/colors';
import { macrometaTokens, coxTokens } from './foundations/semanticTokens';
import components from './components';
import radii from './foundations/radii';
import shadows from './foundations/shadows';
import styles from './styles';
import typography from './foundations/typography';

const overrides = {
  ...chakraTheme,
  components,
  radii,
  shadows,
  styles,
  ...typography,
};

const macrometa = extendTheme(
  {
    ...overrides,
    colors: {
      ...colors,
      ...macrometaColors,
    },
    semanticTokens: macrometaTokens,
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
);

const cox = extendTheme(
  {
    ...overrides,
    colors: {
      ...colors,
      ...coxColors,
    },
    semanticTokens: coxTokens,
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
);

// FIXME: update theme value based on current theme selection
export const toast = createStandaloneToast({ theme: macrometa });

export default {
  macrometa,
  cox,
};
