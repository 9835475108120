import { createIcon } from '@chakra-ui/react';

const QueryWorker = createIcon({
  displayName: 'QueryWorkerIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.721 19.68c-.036-.199-.012-.404.07-.59a1.013 1.013 0 0 1 .927-.613h.055a1.227 1.227 0 1 0 0-2.454h-.105a1.012 1.012 0 0 1-.926-.614v-.049a1.012 1.012 0 0 1 .202-1.117l.037-.037a1.227 1.227 0 1 0-1.736-1.736l-.037.037a1.012 1.012 0 0 1-1.117.202 1.012 1.012 0 0 1-.614-.926v-.056a1.227 1.227 0 1 0-2.454 0v.105a1.013 1.013 0 0 1-.614.926h-.049a1.013 1.013 0 0 1-1.117-.202l-.037-.037a1.227 1.227 0 1 0-1.736 1.736l.037.037a1.012 1.012 0 0 1 .202 1.117 1.011 1.011 0 0 1-.926.663h-.056a1.227 1.227 0 1 0 0 2.454h.105a1.013 1.013 0 0 1 .926.614 1.012 1.012 0 0 1-.202 1.117l-.037.037a1.225 1.225 0 0 0 0 1.736 1.224 1.224 0 0 0 1.338.267c.15-.062.285-.153.399-.267l.036-.037a1.013 1.013 0 0 1 1.117-.202 1.013 1.013 0 0 1 .663.927v.055a1.227 1.227 0 0 0 2.454 0v-.105a1.013 1.013 0 0 1 .614-.926 1.013 1.013 0 0 1 1.117.202l.037.037a1.227 1.227 0 0 0 2.096-.868 1.227 1.227 0 0 0-.36-.868l-.037-.037a1.012 1.012 0 0 1-.272-.528Zm-2.176-2.43a2.295 2.295 0 1 1-4.59 0 2.295 2.295 0 0 1 4.59 0Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.246 1.714c0-.621.504-1.125 1.125-1.125h9.258a1.125 1.125 0 0 1 0 2.25h-.736v5.795a1.125 1.125 0 1 1-2.25 0V2.839h-3.286v5.795a2.91 2.91 0 0 1-.536 1.677l-6.854 9.723a.626.626 0 0 0-.035.707c.13.226.398.42.785.42h6.066a1.125 1.125 0 1 1 0 2.25H3.717c-2.36 0-4.072-2.569-2.589-4.673l6.854-9.723a.66.66 0 0 0 .125-.381V2.839H7.37a1.125 1.125 0 0 1-1.125-1.125Z"
    />,
  ],
});

export default QueryWorker;
