import { Box, Text, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { ProtectedAppRoutes } from 'routing-details';
import { HeaderFooterProps } from '../../types/table';
import AngleLeft from '../../assets/icons/AngleLeft';
import AngleRight from '../../assets/icons/AngleRight';
import Dropdown from '../Dropdown';
import dropdownOptions from '../../constants/dropdownOptions';
import DoubleLeft from '../../assets/icons/DoubleLeft';
import DoubleRight from '../../assets/icons/DoubleRight';
import PaginationButton from '../collection/PaginationButton';

function TablePagination({
  canNextPage,
  canPreviousPage,
  previousPage,
  nextPage,
  pageIndex,
  pageSize,
  totalRecordsCount,
  hidePagination,
  handleLimits,
  gotoPage,
  pageCount,
}: Omit<HeaderFooterProps, 'data' | 'setGlobalFilter' | 'globalFilter'>) {
  const pageStartRow = totalRecordsCount > 0 ? pageIndex * pageSize + 1 : 0;
  const nextPageValue = (pageIndex + 1) * pageSize;
  const pageEndRow = totalRecordsCount < nextPageValue ? totalRecordsCount : nextPageValue;
  const { pathname } = useLocation();
  const enableLimits = typeof handleLimits === 'function';
  const isDynamoCollectionView = pathname.includes('dynamo');

  const getText = () => {
    const splitPathname = pathname.split('/');
    const isCollectionMenu = splitPathname[1] === ProtectedAppRoutes.Collections;

    if (isCollectionMenu && splitPathname.length > 2) {
      if (splitPathname[2] === ProtectedAppRoutes.DynamoCollectionView.split('/')[0]) {
        return 'Item(s)';
      }

      if (!splitPathname[2]) {
        return 'Results';
      }

      return splitPathname[2] === 'redis' ? 'doc(s)' : `${splitPathname[2]}(s)`;
    }
    return 'Results';
  };

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      display="flex"
      color="neutral.500"
      fontSize="small"
      p={3}
    >
      {enableLimits ? (
        <Flex align="center">
          <Dropdown
            options={dropdownOptions.collectionLimit}
            w="200"
            onChange={(e) => handleLimits(e.target.value)}
            data-cy="limitSelector"
            isDisabled={totalRecordsCount <= 10}
          />
          <Text ml="2.5" fontSize="medium">
            results per page
          </Text>
        </Flex>
      ) : (
        <Text ml="2.5" data-cy="resultCount">{`${totalRecordsCount} ${getText()}`}</Text>
      )}
      <Box>
        {pageStartRow}-{pageEndRow} of {totalRecordsCount}
        {!isDynamoCollectionView && (
          <PaginationButton
            ariaLabel="first"
            onClick={() => gotoPage(0)}
            isDisabled={!canPreviousPage}
            id="first"
            icon={<DoubleLeft color="neutral.500" fontSize="md" />}
          />
        )}
        <PaginationButton
          ariaLabel="prev"
          onClick={previousPage}
          isDisabled={!canPreviousPage || hidePagination}
          id="prev"
          icon={<AngleLeft color="neutral.500" fontSize="md" w="2" h="3" />}
        />
        <PaginationButton
          ariaLabel="next"
          onClick={nextPage}
          isDisabled={!canNextPage || hidePagination}
          id="next"
          icon={<AngleRight color="neutral.500" fontSize="md" w="2" h="3" />}
        />
        {!isDynamoCollectionView && (
          <PaginationButton
            ariaLabel="last"
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
            id="last"
            icon={<DoubleRight fill="neutral.500" fontSize="md" />}
          />
        )}
      </Box>
    </Box>
  );
}

export default TablePagination;
