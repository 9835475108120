export var ElementIdentifiers;
(function (ElementIdentifiers) {
    ElementIdentifiers["Dashboard"] = "dashboard";
    ElementIdentifiers["Collections"] = "collections";
    ElementIdentifiers["CollectionDocument"] = "collection-document";
    ElementIdentifiers["CollectionDocumentData"] = "collection-document-data";
    ElementIdentifiers["CollectionIndexes"] = "collection-indexes";
    ElementIdentifiers["CollectionStreams"] = "collection-streams";
    ElementIdentifiers["CollectionSettings"] = "collection-settings";
    ElementIdentifiers["DocumentView"] = "document-view";
    ElementIdentifiers["KVCollectionView"] = "KV-collection-view";
    ElementIdentifiers["KVCollectionData"] = "KV-collection-data";
    ElementIdentifiers["DynamoView"] = "dynamo-view";
    ElementIdentifiers["DynamoData"] = "dynamo-data";
    ElementIdentifiers["DynamoIndexes"] = "dynamo-indexes";
    ElementIdentifiers["DynamoSettings"] = "dynamo-settings";
    ElementIdentifiers["Graphs"] = "graphs";
    ElementIdentifiers["GraphView"] = "graph-view";
    ElementIdentifiers["Search"] = "search";
    ElementIdentifiers["QueryWorkers"] = "query-workers";
    ElementIdentifiers["SavedQueryWorkers"] = "saved-query-workers";
    ElementIdentifiers["QueryEditor"] = "query-editor";
    ElementIdentifiers["RunningQueries"] = "running-queries";
    ElementIdentifiers["SlowQueriesHistory"] = "slow-queries-history";
    ElementIdentifiers["Streams"] = "streams";
    ElementIdentifiers["StreamDetails"] = "stream-details";
    ElementIdentifiers["StreamWorkers"] = "stream-workers";
    ElementIdentifiers["StreamWorkerEditor"] = "stream-worker-editor";
    ElementIdentifiers["StreamWorkerSamples"] = "stream-worker-samples";
    ElementIdentifiers["StreamWorkerLogs"] = "stream-worker-logs";
    ElementIdentifiers["SavedStreamWorkers"] = "saved-stream-workers";
    ElementIdentifiers["GeoFabrics"] = "geo-fabrics";
    ElementIdentifiers["FabricDetails"] = "fabric-details";
    ElementIdentifiers["Locations"] = "locations";
    ElementIdentifiers["Tenants"] = "tenants";
    ElementIdentifiers["TenantTable"] = "tenant-table";
    ElementIdentifiers["PlatformLimits"] = "platform-limits";
    ElementIdentifiers["TenantDetails"] = "tenant-details";
    ElementIdentifiers["Account"] = "account";
    ElementIdentifiers["AccountOverView"] = "account-overView";
    ElementIdentifiers["Users"] = "users";
    ElementIdentifiers["APIKeys"] = "api-keys";
    ElementIdentifiers["Plans"] = "Plans";
    ElementIdentifiers["Attributes"] = "attributes";
    ElementIdentifiers["UserAPIkeyPermission"] = "user-api-key-permission";
    ElementIdentifiers["APIkeyDetails"] = "api-key-details";
    ElementIdentifiers["ApiReference"] = "api";
    ElementIdentifiers["ExternalReference"] = "external-reference";
    ElementIdentifiers["AdminReference"] = "admin-reference";
    ElementIdentifiers["Documentation"] = "documentation";
    ElementIdentifiers["Support"] = "support";
    ElementIdentifiers["Functions"] = "functions";
})(ElementIdentifiers || (ElementIdentifiers = {}));
export var ProtectedAppRoutes;
(function (ProtectedAppRoutes) {
    ProtectedAppRoutes["Dashboard"] = "dashboard";
    ProtectedAppRoutes["Collections"] = "collections";
    ProtectedAppRoutes["CollectionView"] = "doc/:collection";
    ProtectedAppRoutes["DocumentView"] = "doc/:collection/:document";
    ProtectedAppRoutes["RedisCollectionView"] = "redis/:collection";
    ProtectedAppRoutes["RedisCollectionDocumentView"] = "redis/:collection/:document";
    ProtectedAppRoutes["KVCollectionView"] = "kv/:collection";
    ProtectedAppRoutes["DynamoCollectionView"] = "dynamo/:collection";
    ProtectedAppRoutes["QueriesWorkers"] = "query-workers";
    ProtectedAppRoutes["Streams"] = "streams";
    ProtectedAppRoutes["StreamView"] = ":streamName";
    ProtectedAppRoutes["StreamWorkers"] = "stream-workers";
    ProtectedAppRoutes["Search"] = "search";
    ProtectedAppRoutes["Graphs"] = "graphs";
    ProtectedAppRoutes["GraphView"] = ":graphName";
    ProtectedAppRoutes["GeoFabrics"] = "geo-fabrics";
    ProtectedAppRoutes["FabricView"] = ":fabricName";
    ProtectedAppRoutes["Locations"] = "locations";
    ProtectedAppRoutes["Tenants"] = "tenants";
    ProtectedAppRoutes["TenantView"] = ":tenantName";
    ProtectedAppRoutes["Account"] = "accounts";
    ProtectedAppRoutes["Attributes"] = "attributes";
    ProtectedAppRoutes["Permissions"] = "permissions";
    ProtectedAppRoutes["APIKeyView"] = ":apikey";
    ProtectedAppRoutes["ApiReference"] = "api";
    ProtectedAppRoutes["Support"] = "support";
    ProtectedAppRoutes["Documentation"] = "documentation";
    ProtectedAppRoutes["ApiKeys"] = "apiKeys";
    ProtectedAppRoutes["Users"] = "users";
    ProtectedAppRoutes["FunctionWorkers"] = "functions";
})(ProtectedAppRoutes || (ProtectedAppRoutes = {}));
export var RouteLabels;
(function (RouteLabels) {
    RouteLabels["Dashboard"] = "Dashboard";
    RouteLabels["Collections"] = "Collections";
    RouteLabels["Graphs"] = "Graphs";
    RouteLabels["SearchViews"] = "Search Views";
    RouteLabels["QueryWorkers"] = "Query Workers";
    RouteLabels["Streams"] = "Streams";
    RouteLabels["StreamWorkers"] = "Stream Workers";
    RouteLabels["GeoFabrics"] = "Fabrics";
    RouteLabels["Locations"] = "Locations";
    RouteLabels["Tenants"] = "Tenants";
    RouteLabels["Account"] = "Account";
    RouteLabels["APIReference"] = "API Reference";
    RouteLabels["Documentation"] = "Documentation";
    RouteLabels["Support"] = "Support";
    RouteLabels["Functions"] = "Functions";
    RouteLabels["Users"] = "Users";
    RouteLabels["ApiKeys"] = "API Keys";
})(RouteLabels || (RouteLabels = {}));
