import { Box } from '@chakra-ui/react';

export default function PageHeader({ children, p }: { children: React.ReactNode; p?: string }) {
  return (
    <Box bg="white" p={p}>
      {children}
    </Box>
  );
}
PageHeader.defaultProps = {
  p: '4',
};
