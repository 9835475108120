import { createIcon } from '@chakra-ui/react';

const Collection = createIcon({
  displayName: 'CollectionIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.653 2.827Zm.016-.027a.76.76 0 0 1 .182-.165c.222-.158.586-.327 1.095-.48 1.01-.302 2.443-.499 4.054-.499 1.611 0 3.045.197 4.054.5.509.152.873.321 1.095.479a.76.76 0 0 1 .182.165.76.76 0 0 1-.182.165c-.222.158-.586.327-1.095.48-1.01.302-2.443.499-4.054.499-1.611 0-3.045-.197-4.054-.5-.509-.152-.873-.321-1.095-.479A.76.76 0 0 1 1.67 2.8Zm10.678.027Zm0-.053v-.001ZM2.569.899C3.73.549 5.298.344 7 .344s3.269.206 4.431.555c.577.173 1.092.392 1.477.665.368.261.748.67.748 1.236 0 .567-.38.975-.748 1.236-.385.273-.9.492-1.477.665-1.162.35-2.729.555-4.431.555s-3.269-.206-4.431-.555c-.577-.173-1.092-.392-1.477-.665C.724 3.775.344 3.366.344 2.8c0-.567.38-.975.748-1.236.385-.273.9-.492 1.477-.665ZM1.656 6.98A.656.656 0 0 0 .344 7c0 .565.376.973.744 1.236.384.274.897.493 1.473.666 1.16.349 2.727.554 4.439.554 1.712 0 3.278-.205 4.439-.554.576-.173 1.09-.392 1.473-.666.368-.263.744-.671.744-1.236a.656.656 0 0 0-1.312-.02c-.01.02-.05.085-.194.187-.22.157-.582.326-1.089.478-1.006.302-2.44.499-4.061.499-1.622 0-3.055-.197-4.061-.499-.507-.152-.869-.32-1.09-.478-.143-.102-.183-.167-.193-.186Zm10.69-.006v.002-.002Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2.144c.362 0 .656.294.656.656v8.381c.01.02.052.085.194.186.22.157.582.326 1.089.478 1.006.302 2.44.499 4.061.499 1.622 0 3.055-.197 4.061-.499.507-.152.869-.32 1.09-.478.142-.101.183-.166.193-.186V2.8a.656.656 0 0 1 1.312 0v8.4c0 .565-.376.973-.744 1.236-.384.274-.897.493-1.473.666-1.16.349-2.727.554-4.439.554-1.712 0-3.278-.205-4.439-.554-.576-.173-1.09-.392-1.473-.666-.368-.263-.744-.671-.744-1.236V2.8c0-.362.294-.656.656-.656Zm11.347 9.03v.002-.002Z"
    />,
  ],
});

export default Collection;
