import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import posthog from 'posthog-js';
import {
  RouteDetail,
  ProtectedAppRoutes,
  IThemeResponse,
  IThemeDetails,
  RouteLabels,
  AppTabs,
} from 'routing-details';
import { getCurrentUserFabrics, getFabricPermission } from '../apis/fabric';
import { getThemeDetails, getUserConfig } from '../apis/frontend';
import { getTenantDetails } from '../apis/tenants';
import { Fabrics, PermissionType, StandardPlans } from '../constants/common';
import {
  MapRouteLabelsToFeatureGate,
  MapRouteLabelsToFeatureGateKeys,
  OpenAppRoutes,
} from '../constants/route';
import AppSpinner from '../elements/AppSpinner';
import { FrontendConfig, LoginResponse } from '../types/commonTypes';
import { UserConfigResponse } from '../types/provider';
import { Features, TenantDetails, TenantDetailsResponse } from '../types/tenants';
import { generateHash, isAdmin, isNonRoot, setAutoLoginCookies } from '../utils/helper';
import {
  getFabric,
  getRedirectURL,
  getSession,
  removeCookie,
  setFabric,
  setSession,
} from '../utils/persist';
import { ConfigContext } from './configContext';
import { getBillingPermissions } from '../apis/permission';
import { callApi } from '../utils/api';
import { ApiCallMethods } from '../constants/api';
import { getPlans } from '../apis/plans';
import { PlanDetails } from '../types/plans';

function ConfigProvider({ children }: { children: React.ReactNode }) {
  const [themeDetails, setThemeDetails] = useState({} as IThemeDetails);
  const [hasFabricPermission, setFabricPermission] = useState<boolean | undefined>();
  const [hasSystemFabricPermission, setSystemFabricPermission] = useState<boolean | undefined>();
  const [userConfiguration, setUserConfiguration] = useState<RouteDetail[]>([]);
  const [isCookiesExist, setIsCookieExist] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({} as TenantDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const isUserConfigUpdated = useRef(false);
  const [frontendConfig, setFrontendConfig] = useState({} as FrontendConfig);
  const [hasBillingPermission, setBillingPermission] = useState<boolean | undefined>();
  const [isServerDown, setIServerDown] = useState(false);
  const [isAvantGardeFlowEnabled, setIsAvantGardeFlowEnabled] = useState(false);
  const { AccountTabs } = AppTabs;

  useEffect(() => {
    const { token } = getSession();
    if (token) {
      setIsCookieExist(true);
    } else {
      const openRoutes = Object.values(OpenAppRoutes);
      if (!openRoutes.includes(location.pathname as OpenAppRoutes)) {
        window.location.replace(window.location.origin);
      }
    }
  }, [location.pathname]);

  const navigateToLandingPage = useCallback(() => {
    const openRoutes = Object.values(OpenAppRoutes);
    if (isCookiesExist && !openRoutes.includes(location.pathname as OpenAppRoutes)) {
      navigate(`../${location.pathname}`, { replace: true });
    } else {
      // FIXME: Read landing page from /theme api
      navigate(`../${ProtectedAppRoutes.Dashboard}`, { replace: true });
    }
  }, [isCookiesExist, location.pathname, navigate]);

  const logout = useCallback(
    (token?: string, redirectTo?: string) => {
      const { posthogAPIKey, posthogServerURL } = frontendConfig;
      const allCookies = Object.keys(Cookies.get());
      allCookies.forEach((cookieName) => {
        removeCookie(cookieName);
      });
      sessionStorage.clear();
      if (posthogAPIKey && posthogServerURL) {
        posthog.reset();
      }
      if (token) {
        setAutoLoginCookies(token, Fabrics.System, redirectTo);
      }
      const redirectionURL = redirectTo ? `https://${redirectTo}` : window.location.origin;
      window.location.replace(redirectionURL);
    },
    [frontendConfig],
  );

  const fabricQueryResults = useQueries([
    {
      queryKey: 'getUserConfig',
      queryFn: getUserConfig,
      onSuccess: (res: UserConfigResponse) => {
        setUserConfiguration(res.data);
      },
      enabled: false,
      staleTime: Infinity,
    },
    {
      queryKey: 'fabricPermission',
      queryFn: () => getFabricPermission(getFabric()),
      onSuccess: (response: any) => {
        const fabricPermission = response.data.result === PermissionType.READ_WRITE;
        setFabricPermission(fabricPermission);
        if (getFabric() === Fabrics.System) {
          setSystemFabricPermission(fabricPermission);
        }
      },
      enabled: false,
    },
    {
      queryKey: 'getBillingPermissions',
      queryFn: getBillingPermissions,
      onSuccess: ({ data: { result } }: Record<string, any>) => {
        const billingPermission = result.billing === PermissionType.READ_WRITE;
        setBillingPermission(billingPermission);
      },
      enabled: false,
    },
    {
      queryKey: 'getPlans',
      queryFn: () => getPlans(),
      enabled: false,
      onSuccess: (res: { data: PlanDetails[] }) => {
        const playGroundPlan = res.data.find((plan) => plan.name === StandardPlans.PLAYGROUND);
        setIsAvantGardeFlowEnabled(!!playGroundPlan);
      },
    },
  ]);

  const [getUserConfigQuery, fabricPermissionQuery, billingPermissionQuery, getPlansQuery] =
    fabricQueryResults;

  const checkService = useCallback(async () => {
    try {
      await callApi(ApiCallMethods.Get, '/healthz');
    } catch (e: any) {
      if (e.response.status === 503) {
        setIServerDown(true);
        navigate(`.${OpenAppRoutes.Maintenance}`);
      }
    }
  }, [navigate]);

  const queryResults = useQueries([
    {
      queryKey: 'getTenantDetails',
      queryFn: () => {
        const { tenant } = getSession();
        return getTenantDetails(tenant);
      },
      onSuccess: async (res: TenantDetailsResponse) => {
        const {
          result,
          result: { email },
        } = res.data;
        const isLocal = import.meta.env.DEV;
        if (
          !getRedirectURL() &&
          !isLocal &&
          res.data.result?.dnsInfo?.global_url &&
          !window.location.host.startsWith(res.data.result.dnsInfo.global_url.split('.')[0]) &&
          getFabric() === Fabrics.System
        ) {
          const { token } = getSession();
          logout(token, res.data.result.dnsInfo.global_url);
        } else {
          queryResults[1].refetch();
        }

        const { posthogAPIKey, posthogServerURL } = frontendConfig;

        if (!tenantDetails.email && posthogAPIKey && posthogServerURL) {
          const uniqueID = await generateHash(email);
          posthog.identify(uniqueID, { email });
        }
        setTenantDetails(result);
      },
      enabled: isCookiesExist,
      staleTime: Infinity,
      onError: async (err: any) => {
        // FIXME: revisit while implementing https://macrometa.atlassian.net/browse/RACT-600
        if (err?.response?.status === 401) {
          logout();
        } else if (!err.status) {
          await checkService();
        }
      },
    },
    {
      queryKey: 'getCurrentUserFabrics',
      queryFn: getCurrentUserFabrics,
      onSuccess: (res: any) => {
        const fabrics = Object.keys(res.data.result);
        if (!fabrics.includes(Fabrics.System)) {
          const currentFabric = getFabric();
          if (currentFabric === Fabrics.System) {
            setFabric(fabrics[0]);
          }
        } else {
          const existingFabric = getFabric() || Fabrics.System;
          setFabric(existingFabric);
        }
        fabricPermissionQuery.refetch();
        getUserConfigQuery.refetch();
        billingPermissionQuery.refetch();
        getPlansQuery.refetch();
      },
      enabled: false,
      staleTime: Infinity,
    },
  ]);

  const [tenantDetailsQueryInfo] = queryResults;

  const isLoading = useMemo(
    () =>
      queryResults.some((result) => result.isLoading) ||
      fabricQueryResults.some((result) => result.isLoading),
    [fabricQueryResults, queryResults],
  );

  useEffect(() => {
    if (
      userConfiguration.length > 0 &&
      Object.keys(tenantDetails).length > 0 &&
      !isUserConfigUpdated.current &&
      typeof hasBillingPermission === 'boolean' &&
      typeof hasFabricPermission === 'boolean'
    ) {
      isUserConfigUpdated.current = true;
      let newRoutes: RouteDetail[] = [];
      if (!isAdmin()) {
        newRoutes = userConfiguration.filter((userRoutes) => {
          const featureName =
            MapRouteLabelsToFeatureGate[userRoutes.label as MapRouteLabelsToFeatureGateKeys];
          if (featureName) {
            // Functions nav should be visible either for COMPUTE_AKAM or COMPUTE_FAAS that is why added a custom logic here.
            if (featureName === 'COMPUTE_AKAM' || featureName === 'COMPUTE_FAAS') {
              return tenantDetails.features.COMPUTE_AKAM || tenantDetails.features.COMPUTE_FAAS;
            }
            return tenantDetails.features[featureName as keyof Features];
          }
          return true;
        });

        // remove overview if the current user has enterprise plan
        if (tenantDetails.plan === StandardPlans.ENTERPRISE.toUpperCase()) {
          newRoutes = newRoutes.filter((newRoute) => newRoute.label !== RouteLabels.Account);
        }

        if (isNonRoot()) {
          if (!hasFabricPermission) {
            newRoutes = newRoutes.filter((newRoute) => newRoute.label !== RouteLabels.GeoFabrics);
          }
          // remove overview route when non root user has other then 'rw' permission for billing or system.
          // remove overview route when non root user current fabric is other than system fabric
          if (!hasFabricPermission || !hasBillingPermission || getFabric() !== Fabrics.System) {
            newRoutes = newRoutes.filter((newRoute) => {
              const routes = newRoute;
              if (routes.label === RouteLabels.Account) {
                routes.route.children = routes.route.children?.filter(
                  (childrenRoute) => childrenRoute.path !== AccountTabs.overview.path,
                );
                // remove account tab when no there is no overview & plans
                return routes.route.children && routes.route.children.length > 1;
              }
              return true;
            });
          }
        }
        setUserConfiguration(newRoutes);
      }
      if (getFabric() !== Fabrics.System) {
        newRoutes = newRoutes.filter((newRoute) => newRoute.label !== RouteLabels.GeoFabrics);
        setUserConfiguration(newRoutes);
      }
      navigateToLandingPage();
    }
  }, [
    AccountTabs.overview.path,
    hasBillingPermission,
    hasFabricPermission,
    navigateToLandingPage,
    tenantDetails,
    userConfiguration,
  ]);

  useQuery('themeDetails', getThemeDetails, {
    onSuccess: (response: AxiosResponse<IThemeResponse>) => {
      const {
        themeDetails: themeDetailsRes,
        themeDetails: { name: theme },
        fedConfig,
        fedConfig: { sentryDSN, posthogAPIKey, posthogServerURL },
      } = response.data;

      setThemeDetails(themeDetailsRes);
      setFrontendConfig(fedConfig as FrontendConfig);

      if (sentryDSN) {
        Sentry.init({
          dsn: sentryDSN,
          integrations: [new BrowserTracing()],
          tracesSampleRate: 0.2,
        });
        Sentry.setTag('theme', theme);
      }

      if (posthogAPIKey && posthogServerURL) {
        posthog.init(posthogAPIKey, { api_host: posthogServerURL });
      }
    },
    onError: async (err: any) => {
      if (err?.response?.status === 503) {
        await checkService();
      }
    },
  });

  const onLoginResponse = useCallback(
    (res: LoginResponse) => {
      const {
        data: { jwt: token, tenant, username },
      } = res;
      setSession({ token, tenant, username });
      tenantDetailsQueryInfo.refetch();
    },
    [tenantDetailsQueryInfo],
  );

  const refetchUpdateTenantDetails = useCallback((tenantInfo: TenantDetails) => {
    setTenantDetails(tenantInfo);
  }, []);

  if (
    (!themeDetails.name || isLoading || (userConfiguration.length === 0 && isCookiesExist)) &&
    !isServerDown
  ) {
    return <AppSpinner />;
  }

  const { name: theme, documentationURLs } = themeDetails;
  return (
    <ConfigContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        theme,
        onLoginResponse,
        userConfiguration,
        tenantDetails,
        hasFabricPermission,
        setUserConfiguration,
        logout,
        frontendConfig,
        documentationURLs,
        hasBillingPermission,
        hasSystemFabricPermission,
        isAvantGardeFlowEnabled,
        refetchUpdateTenantDetails,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export default ConfigProvider;
