import { createIcon } from '@chakra-ui/react';

const AlertWarning = createIcon({
  displayName: 'AlertWarning',
  viewBox: '0 0 22 22',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 19.8a8.8 8.8 0 1 0 0-17.6 8.8 8.8 0 0 0 0 17.6Zm0 2.2C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11Zm-1.1-6.6h2.2v2.2H9.9v-2.2Zm0-11h2.2v8.8H9.9V4.4Z"
    />
  ),
});

export default AlertWarning;
