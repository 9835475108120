import { createIcon } from '@chakra-ui/react';

const FunctionWorker = createIcon({
  displayName: 'FunctionWorker',
  viewBox: '0 0 15 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.964 1.187a.7.7 0 0 1 .498.67v3.256h1.967a.7.7 0 0 1 .583 1.087l-4.19 6.33a.7.7 0 0 1-1.284-.387V8.887H3.571A.7.7 0 0 1 2.988 7.8l4.19-6.33a.7.7 0 0 1 .786-.283Zm-3.09 6.3h1.364a.7.7 0 0 1 .7.7v1.63l2.188-3.304H7.762a.7.7 0 0 1-.7-.7v-1.63L4.874 7.487Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.734 2.372a.6.6 0 0 1 .009.849A5.38 5.38 0 0 0 1.2 7a5.38 5.38 0 0 0 1.543 3.78.6.6 0 0 1-.857.84A6.58 6.58 0 0 1 0 7c0-1.798.72-3.43 1.886-4.62a.6.6 0 0 1 .848-.008ZM11.27 2.372a.6.6 0 0 1 .848.009A6.58 6.58 0 0 1 14.004 7a6.58 6.58 0 0 1-1.886 4.62.6.6 0 0 1-.857-.84A5.38 5.38 0 0 0 12.804 7a5.38 5.38 0 0 0-1.543-3.78.6.6 0 0 1 .01-.848Z"
    />,
  ],
});

export default FunctionWorker;
