import { createIcon } from '@chakra-ui/react';

const Users = createIcon({
  displayName: 'UsersIcon',
  viewBox: '0 0 14 14',
  path: (
    <path
      fillRule="evenodd"
      d="M5.167 2.583a1.48 1.48 0 1 0 0 2.959 1.48 1.48 0 0 0 0-2.959ZM2.52 4.063a2.646 2.646 0 1 1 5.292 0 2.646 2.646 0 0 1-5.292 0ZM5.167 8.542a3.542 3.542 0 0 0-3.542 3.541v.334h7.083v-.334a3.542 3.542 0 0 0-3.541-3.541Zm-3.33.212a4.708 4.708 0 0 1 8.038 3.33V13a.583.583 0 0 1-.583.583h-8.25A.583.583 0 0 1 .458 13v-.917c0-1.248.496-2.446 1.38-3.329ZM8.25 2c0-.322.261-.583.583-.583a2.646 2.646 0 1 1 0 5.291.583.583 0 1 1 0-1.166 1.48 1.48 0 1 0 0-2.959A.583.583 0 0 1 8.25 2ZM9.755 7.925a.583.583 0 0 1 .752-.338 4.708 4.708 0 0 1 3.035 4.395V13a.583.583 0 0 1-.584.583h-1.375a.583.583 0 1 1 0-1.166h.792v-.434a3.542 3.542 0 0 0-2.282-3.305.583.583 0 0 1-.338-.753Z"
      clipRule="evenodd"
    />
  ),
});

export default Users;
