import { createIcon } from '@chakra-ui/react';

const Documentation = createIcon({
  displayName: 'DocumentationIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.344 1C.344.638.638.344 1 .344h3.6A3.056 3.056 0 0 1 7.656 3.4v8.4a.656.656 0 0 1-1.312 0A1.144 1.144 0 0 0 5.2 10.656H1A.656.656 0 0 1 .344 10V1Zm6 8.626V3.4A1.744 1.744 0 0 0 4.6 1.656H1.656v7.688H5.2c.402 0 .794.098 1.144.282Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.239 1.239A3.056 3.056 0 0 1 9.4.344H13c.362 0 .656.294.656.656v9a.656.656 0 0 1-.656.656H8.8A1.144 1.144 0 0 0 7.656 11.8a.656.656 0 0 1-1.312 0V3.4c0-.81.322-1.588.895-2.161Zm.417 8.387c.35-.184.742-.282 1.144-.282h3.544V1.656H9.4A1.744 1.744 0 0 0 7.656 3.4v6.226Z"
    />,
  ],
});

export default Documentation;
