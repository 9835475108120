import '@fontsource/source-code-pro';

export default {
  fonts: {
    body: `Averta, sans-serif`,
    heading: `Averta, sans-serif`,
    mono: `"Source Code Pro", monospace`,
  },

  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '0.9375rem', // 15px
    lg: '1rem', // 16px
    xl: '1.125rem', // 18px
    '2xl': '1.3125rem', // 21px
    '3xl': '1.6875rem', // 27px
    '4xl': '2.25rem', // 36px
    '5xl': '2.75rem', // 44px
    '6xl': '3.75rem', // 60px
  },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  letterSpacings: {
    tighter: '-0.025em',
    tight: '-0.0125em',
    normal: '0',
    wide: '0.0125em',
    wider: '0.025em',
    widest: '0.1em',
  },

  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
  },

  sizes: {
    25: '6.25rem',
    37: '9.375rem',
    49: '12.5rem',
    81.5: '21.875rem',
    97.5: '25.75rem',
  },
};
