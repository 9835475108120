import { createIcon } from '@chakra-ui/react';

const Dashboard = createIcon({
  displayName: 'DashboardIcon',
  viewBox: '0 0 14 14',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.923 1.7c-.123 0-.223.1-.223.223v2.77c0 .123.1.222.223.222h2.77c.123 0 .222-.1.222-.223V1.923c0-.123-.1-.223-.223-.223H1.923ZM.3 1.923C.3 1.027 1.027.3 1.923.3h2.77c.896 0 1.622.727 1.622 1.623v2.77c0 .896-.726 1.622-1.623 1.622H1.923A1.623 1.623 0 0 1 .3 4.692V1.923ZM9.308 1.7c-.124 0-.223.1-.223.223v2.77c0 .123.1.222.223.222h2.769c.123 0 .223-.1.223-.223V1.923c0-.123-.1-.223-.223-.223h-2.77Zm-1.623.223C7.685 1.027 8.41.3 9.308.3h2.769c.896 0 1.623.727 1.623 1.623v2.77c0 .896-.727 1.622-1.623 1.622h-2.77a1.623 1.623 0 0 1-1.622-1.623V1.923ZM1.923 9.085c-.123 0-.223.1-.223.223v2.769c0 .123.1.223.223.223h2.77c.123 0 .222-.1.222-.223v-2.77c0-.123-.1-.222-.223-.222H1.923ZM.3 9.308c0-.897.727-1.623 1.623-1.623h2.77c.896 0 1.622.726 1.622 1.623v2.769c0 .896-.726 1.623-1.623 1.623H1.923A1.623 1.623 0 0 1 .3 12.077v-2.77ZM9.308 9.085c-.124 0-.223.1-.223.223v2.769c0 .123.1.223.223.223h2.769c.123 0 .223-.1.223-.223v-2.77c0-.123-.1-.222-.223-.222h-2.77Zm-1.623.223c0-.897.726-1.623 1.623-1.623h2.769c.896 0 1.623.726 1.623 1.623v2.769c0 .896-.727 1.623-1.623 1.623h-2.77a1.623 1.623 0 0 1-1.622-1.623v-2.77Z"
    />
  ),
});

export default Dashboard;
