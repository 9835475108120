import { createIcon } from '@chakra-ui/react';

const SearchInput = createIcon({
  displayName: 'SearchInputIcon',
  viewBox: '0 0 16 16',
  path: (
    <path d="m16 15.165-4.47-4.494a6.514 6.514 0 1 0-.86.858L15.166 16l.835-.835ZM1.196 6.525a5.33 5.33 0 1 1 10.659 0 5.33 5.33 0 0 1-10.66 0Z" />
  ),
});

export default SearchInput;
