import { createIcon } from '@chakra-ui/react';

const Globe = createIcon({
  displayName: 'Globe',
  viewBox: '0 0 14 14',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.633 6.4h2.504A10.957 10.957 0 0 1 5.73 1.75 5.404 5.404 0 0 0 1.633 6.4ZM7 2a9.757 9.757 0 0 0-1.655 4.4h3.31A9.757 9.757 0 0 0 7 2Zm1.655 5.6A9.757 9.757 0 0 1 7 12a9.757 9.757 0 0 1-1.655-4.4h3.31Zm-4.518 0H1.633a5.404 5.404 0 0 0 4.097 4.65A10.957 10.957 0 0 1 4.137 7.6Zm4.133 4.65A10.957 10.957 0 0 0 9.863 7.6h2.504a5.404 5.404 0 0 1-4.097 4.65Zm4.097-5.85H9.863A10.957 10.957 0 0 0 8.27 1.75a5.404 5.404 0 0 1 4.097 4.65ZM.4 7a6.6 6.6 0 1 1 13.2 0A6.6 6.6 0 0 1 .4 7Z"
    />
  ),
});
export default Globe;
