import { AccountTabKeys, ApiReferenceKeys } from "./common";
export default {
    collectionTabs: {
        documents: {
            path: "",
            label: "Data",
        },
        indexes: {
            path: "indexes",
            label: "Indexes",
        },
        stream: {
            path: "stream",
            label: "Stream",
        },
        settings: {
            path: "settings",
            label: "Settings",
        },
    },
    DynamoCollectionTabs: {
        documents: {
            path: "",
            label: "Data",
        },
        indexes: {
            path: "indexes",
            label: "Indexes",
        },
        settings: {
            path: "settings",
            label: "Settings",
        },
    },
    queryTabs: {
        editor: {
            path: "",
            label: "Editor",
        },
        queryWorker: {
            path: "saved-query-workers",
            label: "Query Workers",
        },
        runningQueries: {
            path: "running-queries",
            label: "Running Queries",
        },
        slowQueryHistory: {
            path: "slow-queries-history",
            label: "Slow Query History",
        },
    },
    streamTabs: {
        editor: {
            path: "",
            label: "Editor",
        },
        savedStreamWorkers: {
            path: "saved-stream-workers",
            label: "Stream Workers",
        },
        samples: {
            path: "samples",
            label: "Samples",
        },
        logs: {
            path: "logs",
            label: "Logs",
        },
    },
    TenantTabs: {
        tenant: {
            path: "list",
            label: "Tenants",
        },
        limits: {
            path: "limits",
            label: "Platform Limits",
        },
    },
    AccountTabs: {
        [AccountTabKeys.Overview]: {
            path: "overview",
            label: "Overview",
        },
        [AccountTabKeys.Plans]: {
            path: "plans",
            label: "Plans",
        },
    },
    ApiReferenceTabs: {
        [ApiReferenceKeys.External]: {
            path: "",
            label: "API",
        },
        [ApiReferenceKeys.Internal]: {
            path: "internal",
            label: "Internal API",
        },
    },
    ApiKeyDetailsTabs: {
        back: {
            path: "back",
            label: "Back",
        },
        permissions: {
            path: "",
            label: "Permissions",
        },
        attributes: {
            path: "attributes",
            label: "Attributes",
        },
    },
};
