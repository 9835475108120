import { createIcon } from '@chakra-ui/react';

const Search = createIcon({
  displayName: 'SearchIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.86 4.84a2.175 2.175 0 1 0 0 4.35 2.175 2.175 0 0 0 0-4.35ZM5.285 7.015a3.575 3.575 0 1 1 7.15 0 3.575 3.575 0 0 1-7.15 0Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.48 8.759a.714.714 0 0 1 1.01 0l2 2a.714.714 0 1 1-1.01 1.01l-2-2a.714.714 0 0 1 0-1.01ZM0 4.8a.7.7 0 0 1 .7-.7h2.6a.7.7 0 1 1 0 1.4H.7a.7.7 0 0 1-.7-.7ZM0 1.064a.7.7 0 0 1 .7-.7h5.6a.7.7 0 1 1 0 1.4H.7a.7.7 0 0 1-.7-.7ZM0 8.55a.7.7 0 0 1 .7-.7h2.1a.7.7 0 1 1 0 1.4H.7a.7.7 0 0 1-.7-.7ZM0 12.3a.7.7 0 0 1 .7-.7h4.6a.7.7 0 1 1 0 1.4H.7a.7.7 0 0 1-.7-.7Z"
    />,
  ],
});

export default Search;
