import { createIcon } from '@chakra-ui/react';

const Filter = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 16 16',
  path: (
    <path d="M15.932.443c.128.31.075.576-.16.796L10.18 6.84v8.432c0 .318-.148.541-.443.67a.788.788 0 0 1-.284.057.674.674 0 0 1-.51-.216l-2.905-2.909a.7.7 0 0 1-.216-.511V6.84L.227 1.239C-.007 1.019-.06.754.068.443.197.148.42 0 .738 0h14.524c.318 0 .541.148.67.443Z" />
  ),
});

export default Filter;
