import { createIcon } from '@chakra-ui/react';

const APIKeys = createIcon({
  displayName: 'APIKeysIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      d="M7.356 1.583c-1.324 0-2.537.47-3.485 1.252a.583.583 0 1 1-.742-.9 6.644 6.644 0 1 1 0 10.25.583.583 0 1 1 .742-.9 5.477 5.477 0 1 0 3.485-9.702Z"
      clipRule="evenodd"
    />,
    <path
      fillRule="evenodd"
      d="M2.5 8.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Zm0 1.167a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      clipRule="evenodd"
    />,
    <path
      fillRule="evenodd"
      d="M3.917 6.75c0-.322.26-.583.583-.583H10a.583.583 0 1 1 0 1.166H4.5a.583.583 0 0 1-.583-.583Z"
      clipRule="evenodd"
    />,
    <path
      fillRule="evenodd"
      d="M7.5 6.167c.322 0 .583.26.583.583V9a.583.583 0 0 1-1.166 0V6.75c0-.322.26-.583.583-.583ZM10 6.167c.322 0 .583.26.583.583v1.5a.583.583 0 1 1-1.166 0v-1.5c0-.322.26-.583.583-.583Z"
      clipRule="evenodd"
    />,
  ],
});

export default APIKeys;
