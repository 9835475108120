import { createIcon } from '@chakra-ui/react';

const AlertCritical = createIcon({
  displayName: 'AlertCritical',
  viewBox: '0 0 22 22',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0Zm0 3.008a7.992 7.992 0 1 1 0 15.984 7.992 7.992 0 0 1 0-15.984ZM5.16 8.839v4.322h11.68V8.838H5.16Z"
    />
  ),
});

export default AlertCritical;
