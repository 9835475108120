// eslint-disable-next-line import/prefer-default-export
export enum ApiCallMethods {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete',
}

export enum Headers {
  XC8SyncId = 'x-c8-async-id',
  XC8Async = 'store',
  X_C8_ERROR_CODES = 'x-c8-error-codes',
}
