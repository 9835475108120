import { Box, HStack, Button, Flex } from '@chakra-ui/react';
import { useRef } from 'react';
import ReactAce from 'react-ace/lib/ace';
import { AlertStatus } from '../../constants/common';
import { StringConstants } from '../../constants/userMessages';
import AppAceEditor from '../../elements/AceEditor';
import AppModal from '../../elements/AppModal';
import { useToast } from '../../providers/toastContext';
import { DynamoModalProps } from '../../types/collections';

function DynamoItemModal({
  isOpen,
  data,
  isEdit,
  toggleModal,
  handleSubmit,
  readOnly,
}: DynamoModalProps) {
  const dynamoEditorRef = useRef<ReactAce>(null);
  const { appToast } = useToast();

  const onClose = () => {
    toggleModal();
  };

  const handleCreate = () => {
    try {
      handleSubmit(dynamoEditorRef.current?.editor.getValue() as string, true);
    } catch (err) {
      appToast({
        alertDescription: StringConstants.INVALID_JSON,
        alertStatus: AlertStatus.ERROR,
      });
    }
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={isEdit ? 'Edit Item' : 'New Item'}
      size="2xl"
      modalFooter={
        <Flex justify="space-between">
          <Button variant="neutral" onClick={onClose}>
            Cancel
          </Button>
          {isEdit ? (
            <HStack>
              <Button
                variant="neutral"
                onClick={() => {
                  handleSubmit(dynamoEditorRef.current?.editor.getValue() as string, false);
                }}
                disabled={readOnly}
              >
                Delete
              </Button>
              <Button onClick={handleCreate} disabled={readOnly}>
                Update
              </Button>
            </HStack>
          ) : (
            <Button onClick={handleCreate} disabled={readOnly}>
              Create
            </Button>
          )}
        </Flex>
      }
    >
      <Box>
        <AppAceEditor
          name="createDynamoItem"
          value={JSON.stringify(data, null, 2)}
          editorRef={dynamoEditorRef}
          readOnly={readOnly}
        />
      </Box>
    </AppModal>
  );
}

export default DynamoItemModal;
