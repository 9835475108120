import { createIcon } from '@chakra-ui/react';

const Access = createIcon({
  displayName: 'AccessIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.033 6.103a.406.406 0 0 0-.408.403v5.924c0 .22.18.403.408.403h7.934a.406.406 0 0 0 .408-.403V6.506c0-.22-.18-.403-.408-.403H3.033Zm-1.575.403c0-.87.708-1.57 1.575-1.57h7.934c.867 0 1.575.7 1.575 1.57v5.924c0 .87-.708 1.57-1.575 1.57H3.033c-.867 0-1.575-.7-1.575-1.57V6.506Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.134 1.182a4.062 4.062 0 0 1 5.732 0 4.03 4.03 0 0 1 1.188 2.856V5.52a.583.583 0 0 1-1.166 0V4.04c0-.761-.304-1.491-.845-2.03a2.896 2.896 0 0 0-4.086 0 2.864 2.864 0 0 0-.844 2.03v1.48a.583.583 0 0 1-1.167 0V4.04c0-1.072.428-2.1 1.188-2.857ZM7 9.59a.123.123 0 1 0 0-.245.123.123 0 0 0 0 .245Zm-1.044-.122a1.044 1.044 0 1 1 2.088 0 1.044 1.044 0 0 1-2.088 0Z"
    />,
  ],
});

export default Access;
