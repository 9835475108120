import { createIcon } from '@chakra-ui/react';

const Data = createIcon({
  displayName: 'DataIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.923 2.115c-.123 0-.223.1-.223.224V6.03c0 .123.1.223.223.223h10.154c.123 0 .223-.1.223-.223V2.339c0-.124-.1-.224-.223-.224H1.923ZM.3 2.34c0-.897.727-1.624 1.623-1.624h10.154c.896 0 1.623.727 1.623 1.624V6.03c0 .896-.727 1.623-1.623 1.623H1.923A1.623 1.623 0 0 1 .3 6.03V2.339Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.775 6.73a1.623 1.623 0 0 1 1.148-.476.7.7 0 1 1 0 1.4.223.223 0 0 0-.223.223v3.692a.223.223 0 0 0 .223.223h10.154a.223.223 0 0 0 .223-.223V7.877a.223.223 0 0 0-.223-.223.7.7 0 1 1 0-1.4A1.623 1.623 0 0 1 13.7 7.877v3.692a1.623 1.623 0 0 1-1.623 1.623H1.923A1.623 1.623 0 0 1 .3 11.57V7.877c0-.43.171-.843.475-1.148ZM3.77 4.423a.238.238 0 1 0 0-.477.238.238 0 0 0 0 .477Zm-1.162-.238a1.162 1.162 0 1 1 2.323 0 1.162 1.162 0 0 1-2.323 0ZM6.762 4.185a.7.7 0 0 1 .7-.7h3.23a.7.7 0 1 1 0 1.4h-3.23a.7.7 0 0 1-.7-.7Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.77 9.962a.238.238 0 1 0 0-.477.238.238 0 0 0 0 .477Zm-1.162-.239a1.162 1.162 0 1 1 2.323 0 1.162 1.162 0 0 1-2.323 0ZM6.762 9.723a.7.7 0 0 1 .7-.7h3.23a.7.7 0 1 1 0 1.4h-3.23a.7.7 0 0 1-.7-.7Z"
    />,
  ],
});

export default Data;
