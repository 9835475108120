/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
// FIXME: this will add additional 25KBs to bundle. Try to resolve it via alternative means
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import {
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  Row,
  TableOptions,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { DataTableProps } from '../../types/table';
import SearchInput from '../../assets/icons/SearchInput';
import { NumberConstants } from '../../constants/userMessages';

const DEFAULT_PAGE_SIZE = 10;

function DataTable(props: DataTableProps) {
  const {
    columns,
    data,
    fetchData,
    totalRecordsCount,
    setData,
    header: HeaderComponent,
    footer: FooterComponent,
    extra,
    filterInputPlaceholder,
    getSelectedRowIds,
    hiddenColumns,
    handleRowClick,
    hidePagination,
    tableLayout,
    hideToolTip,
    handleLimits,
  } = props;
  const [selectedRowsId, setSelectedRowsId] = useState<Record<string, boolean>>({});
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const hasLimits = typeof handleLimits === 'function';

  const tableOptions: TableOptions<object> = {
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize,
      hiddenColumns: hiddenColumns ?? [],
    },
    autoResetSelectedRows: false,
  };
  if (typeof totalRecordsCount === 'number') {
    tableOptions.manualPagination = true;
    // we are calculating the total number of pages based pageSize
    tableOptions.pageCount = Math.ceil(totalRecordsCount / pageSize);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    rows,
    state: { pageIndex, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
    gotoPage,
    toggleRowSelected,
  } = useTable(tableOptions, useGlobalFilter, usePagination, useRowSelect);

  useEffect(() => {
    if (typeof fetchData === 'function') {
      fetchData({ pageIndex, pageSize });
    }
  }, [pageIndex, pageSize, fetchData]);

  const [value, setValue] = useState(globalFilter);
  const isRowSelectable = typeof getSelectedRowIds === 'function';

  useEffect(() => {
    if (!isRowSelectable && Object.keys(selectedRowsId).length) {
      Object.keys(selectedRowsId).map((item) => toggleRowSelected(item, false));
      setSelectedRowsId({});
    }
  }, [isRowSelectable, selectedRowsId, toggleRowSelected]);

  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  const onRowClicked = (row: Row<object>) => {
    if (isRowSelectable) {
      if (row.isSelected) {
        delete selectedRowsId[`${row.id}`];
      } else {
        selectedRowsId[`${row.id}`] = true;
      }
      row.toggleRowSelected(!row.isSelected);
      setSelectedRowsId(selectedRowsId);
      getSelectedRowIds(selectedRowsId);
    } else if (handleRowClick) {
      handleRowClick(row);
    }
  };

  const checkCellValueLength = (cellValue: any) =>
    cellValue && JSON.stringify(cellValue).length > NumberConstants.FILE_NAME_MAX_LENGTH;

  const rowHoverCursor = handleRowClick ? 'pointer' : 'inherit';

  useEffect(() => {
    // clear filter on adding/deleting a new record
    setValue('');
  }, [data.length]);

  useEffect(() => {
    // resetting page index to its initial value, if the filtered results are less than default page size
    if (totalRecordsCount && totalRecordsCount < pageSize) {
      gotoPage(0);
    }
  }, [totalRecordsCount, gotoPage, pageSize]);

  const handleOnChangeLimits = (limit: string) => {
    setPageSize(Number(limit));
    if (hasLimits) {
      handleLimits(limit);
    }
  };

  return (
    <>
      <HStack spacing="3" px="4" pt="3">
        {extra?.inviteUsers && (
          <Text fontSize="sm" fontWeight="bold">
            Invited Users
          </Text>
        )}
        {filterInputPlaceholder && (
          <InputGroup maxWidth="56">
            <InputLeftElement pointerEvents="none">
              {/* FIXME: update search icon, has strokeWidth */}
              <SearchInput mb="1.5" fill="neutral.300" fontSize="md" ml="2" />
            </InputLeftElement>
            <Input
              placeholder={filterInputPlaceholder}
              value={value || ''}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              autoFocus
              size="sm"
              focusBorderColor="positive"
            />
          </InputGroup>
        )}
        {HeaderComponent && (
          <HeaderComponent
            pageCount={pageCount}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            previousPage={previousPage}
            data={data}
            setData={setData}
            extra={extra}
            pageIndex={pageIndex}
            pageSize={pageSize}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            totalRecordsCount={totalRecordsCount}
          />
        )}
      </HStack>
      <TableContainer>
        <Table
          {...getTableProps()}
          sx={{
            tableLayout,
            marginTop: `${filterInputPlaceholder ? '3' : '0'}`,
          }}
        >
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                borderTop={`${filterInputPlaceholder ? '1px' : 'none'}`}
                borderColor="neutral.100"
              >
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps({
                      style: { width: column.width },
                    })}
                    fontSize="13px"
                    fontWeight="normal"
                    textTransform="uppercase"
                    whiteSpace="pre-wrap"
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr
                  data-cy={`row${index}`}
                  _hover={{
                    backgroundColor: isRowSelectable ? 'neutral.50' : 'inherit',
                  }}
                  {...row.getRowProps()}
                  onClick={() => onRowClicked(row)}
                  bg={row.isSelected ? 'select_document_bg_color' : 'inherit'}
                  cursor={isRowSelectable ? 'copy' : rowHoverCursor}
                  data-row-id={row.values.name || row.values.topic} // FIXME: Find a permanent solution than having multiple conditions
                >
                  {row.cells.map((cell) => (
                    <Tooltip
                      key={cell.getCellProps().key}
                      hasArrow
                      label={!hideToolTip && checkCellValueLength(cell.value) && cell.value}
                      placement="top-start"
                    >
                      <Td
                        data-cell-id={cell.column.getHeaderProps().key}
                        {...cell.getCellProps()}
                        whiteSpace="break-spaces"
                        overflow="hidden"
                        textOverflow={checkCellValueLength(cell.value) && 'ellipsis'}
                        fontSize="md"
                      >
                        {cell.render('Cell')}
                      </Td>
                    </Tooltip>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {FooterComponent && (
        <FooterComponent
          pageCount={pageCount}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          previousPage={previousPage}
          data={data}
          setData={setData}
          extra={extra}
          pageIndex={pageIndex}
          pageSize={pageSize}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          totalRecordsCount={totalRecordsCount ?? rows.length}
          hidePagination={hidePagination}
          handleLimits={hasLimits && handleOnChangeLimits}
          gotoPage={gotoPage}
        />
      )}
    </>
  );
}

export default DataTable;

DataTable.defaultProps = {
  selectedRowIndexes: [],
  handleRowSelection: () => null,
  hiddenColumns: [],
  hidePagination: false,
  tableLayout: 'fixed',
};
