import { createIcon } from '@chakra-ui/react';

const Plus = createIcon({
  displayName: 'Plus',
  viewBox: '0 0 16 16',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.8 4C8.8 3.78783 8.71571 3.58434 8.56568 3.43431C8.41565 3.28429 8.21217 3.2 8 3.2C7.78782 3.2 7.58434 3.28429 7.43431 3.43431C7.28428 3.58434 7.2 3.78783 7.2 4V7.2H4C3.78782 7.2 3.58434 7.28429 3.43431 7.43431C3.28428 7.58434 3.2 7.78783 3.2 8C3.2 8.21217 3.28428 8.41566 3.43431 8.56569C3.58434 8.71571 3.78782 8.8 4 8.8H7.2V12C7.2 12.2122 7.28428 12.4157 7.43431 12.5657C7.58434 12.7157 7.78782 12.8 8 12.8C8.21217 12.8 8.41565 12.7157 8.56568 12.5657C8.71571 12.4157 8.8 12.2122 8.8 12V8.8H12C12.2122 8.8 12.4157 8.71571 12.5657 8.56569C12.7157 8.41566 12.8 8.21217 12.8 8C12.8 7.78783 12.7157 7.58434 12.5657 7.43431C12.4157 7.28429 12.2122 7.2 12 7.2H8.8V4Z"
    />,
  ],
});

export default Plus;
