import { createIcon } from '@chakra-ui/react';

const Location = createIcon({
  displayName: 'LocationIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.606 17.514c2.031-2.285 3.569-5.021 3.569-7.51 0-3.719-3.383-7.054-7.175-7.054-3.794 0-7.175 3.332-7.175 7.05 0 2.491 1.538 5.229 3.57 7.513.98 1.104 1.994 2.007 2.817 2.616.312.231.578.406.788.53.21-.124.476-.299.788-.53.823-.61 1.836-1.512 2.818-2.616Zm-3.986 3.338Zm.742-.006ZM12 23c1.5 0 9.375-6.4 9.375-12.996C21.375 5.034 16.971.75 12 .75 7.03.75 2.625 5.03 2.625 10c0 6.6 7.875 13 9.375 13Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.839A1.72 1.72 0 1 0 12 8.4a1.72 1.72 0 0 0 0 3.439Zm0 2.2a3.92 3.92 0 1 0 0-7.84 3.92 3.92 0 0 0 0 7.84Z"
    />,
  ],
});

export default Location;
