import { createIcon } from '@chakra-ui/react';

const Compute = createIcon({
  displayName: 'ComputeIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.23 4.008c-.122 0-.222.1-.222.223v5.538c0 .123.1.223.223.223h5.538c.123 0 .223-.1.223-.223V4.231c0-.123-.1-.223-.223-.223H4.231Zm-1.622.223c0-.897.726-1.623 1.623-1.623h5.538c.897 0 1.623.726 1.623 1.623v5.538c0 .897-.726 1.623-1.623 1.623H4.231A1.623 1.623 0 0 1 2.608 9.77V4.231Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.154.3a.7.7 0 0 1 .7.7v2.308a.7.7 0 1 1-1.4 0V1a.7.7 0 0 1 .7-.7ZM8.846.3a.7.7 0 0 1 .7.7v2.308a.7.7 0 1 1-1.4 0V1a.7.7 0 0 1 .7-.7ZM.3 8.846a.7.7 0 0 1 .7-.7h2.308a.7.7 0 1 1 0 1.4H1a.7.7 0 0 1-.7-.7ZM.3 5.154a.7.7 0 0 1 .7-.7h2.308a.7.7 0 1 1 0 1.4H1a.7.7 0 0 1-.7-.7ZM8.846 9.992a.7.7 0 0 1 .7.7V13a.7.7 0 1 1-1.4 0v-2.308a.7.7 0 0 1 .7-.7ZM5.154 9.992a.7.7 0 0 1 .7.7V13a.7.7 0 1 1-1.4 0v-2.308a.7.7 0 0 1 .7-.7ZM9.992 5.154a.7.7 0 0 1 .7-.7H13a.7.7 0 1 1 0 1.4h-2.308a.7.7 0 0 1-.7-.7ZM9.992 8.846a.7.7 0 0 1 .7-.7H13a.7.7 0 1 1 0 1.4h-2.308a.7.7 0 0 1-.7-.7ZM5.838 7.462a.7.7 0 0 1 .7-.7h1.847a.7.7 0 1 1 0 1.4H6.538a.7.7 0 0 1-.7-.7Z"
    />,
  ],
});

export default Compute;
