/* eslint-disable react/jsx-props-no-spreading */
import { Tabs, TabList, Tab } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AppTabProps } from '../types/appTabs';
import { toCamelCase } from '../utils/helper';

function AppTabs({ tabDetails, basePath, tabListProps, ...props }: AppTabProps) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { pathname } = useLocation();

  const tabDetailValues = Object.values(tabDetails);
  const tabs = tabDetailValues.map(({ label }) => label);

  useEffect(() => {
    // controlled tabIndex to have correct "selected tab" even on page reloads
    const foundIndex = tabDetailValues.findIndex(({ path }) => {
      const isMatch = matchPath(pathname, `/${basePath}/${path.trim()}`);
      return isMatch;
    });

    setCurrentTab(foundIndex);
  }, [basePath, pathname, tabDetailValues]);

  return (
    <Tabs {...props} index={currentTab} backgroundColor="white">
      <TabList borderBottomWidth="thin" borderBottomColor="neutral.200" {...tabListProps}>
        {tabs.map((label) => (
          <Tab
            key={label}
            data-cy={toCamelCase(label)}
            color="subnav_color"
            _selected={{
              color: 'subnav_selected_color',
              borderColor: 'subnav_selected_border_color',
            }}
            sx={{
              ':hover': {
                color: 'subnav_hover_color',
              },
              ':hover[aria-selected=true]': {
                color: 'subnav_selected_color',
              },
            }}
            fontSize="md"
            px="3"
          >
            {label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}

export default AppTabs;
