import axios from 'axios';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ElementIdentifiers, ProtectedAppRoutes } from 'routing-details';
import { AlertStatus } from '../constants/common';
import { OpenAppRoutes } from '../constants/route';
import { useConfig } from '../providers/configContext';
import { useToast } from '../providers/toastContext';
import { getBaseURL } from './federation';
import { getFabric, getSession } from './persist';

type Props = {
  children: React.ReactElement;
};

const axiosInstance = axios.create();

function AxiosInterceptors({ children }: Props) {
  const userConfig = useConfig();
  const { appToast } = useToast();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const checkService = useCallback(async () => {
    try {
      await axiosInstance.get('/healthz');
    } catch (e: any) {
      if (e.response.status === 503) {
        navigate(`.${OpenAppRoutes.Maintenance}`);
      }
    }
  }, [navigate]);

  useEffect(() => {
    axiosInstance.interceptors.request.use(
      (config) => config,
      (error) => Promise.reject(error),
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const getVersionUrl = `${getBaseURL()}/_fabric/${getFabric()}/_api/version`;
        if (error.response?.status === 401 && error.response?.config.url === getVersionUrl) {
          userConfig.logout();
        } else if (error.response?.status === 401) {
          const { token } = getSession();
          axiosInstance.get(getVersionUrl, {
            headers: {
              Authorization: `bearer ${token}`,
            },
          });
        } else if (
          error.response?.status === 403 &&
          error.response?.data.errorNum &&
          error.response?.data.errorNum === 102036
        ) {
          appToast({
            alertStatus: AlertStatus.ERROR,
            alertDescription: error.response.data.errorMessage,
          });
          const accountOverViewRoute = userConfig.userConfiguration.filter(
            (item) => item.route.element === ElementIdentifiers.Account,
          );
          navigate(`./${ProtectedAppRoutes.Account}`);
          userConfig.setUserConfiguration(accountOverViewRoute);
        } else if (!error.response) {
          await checkService();
        } else if (error.response?.status === 503) {
          navigate(`.${OpenAppRoutes.Maintenance}`);
        }
        return Promise.reject(error);
      },
    );

    return () => axiosInstance.interceptors.response.eject(responseInterceptor);
  }, [appToast, navigate, userConfig, checkService, pathname]);

  return children;
}

export default axiosInstance;
export { AxiosInterceptors };
