import { createIcon } from '@chakra-ui/react';

const AngleLeft = createIcon({
  displayName: 'AngleLeft',
  viewBox: '0 0 7 12',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.065.81a.875.875 0 0 1 0 1.237L2.112 6l3.953 3.953a.875.875 0 0 1-1.237 1.237L.256 6.62a.875.875 0 0 1 0-1.238L4.828.81a.875.875 0 0 1 1.237 0Z"
    />
  ),
});

export default AngleLeft;
