import { createIcon } from '@chakra-ui/react';

const AlertError = createIcon({
  displayName: 'AlertError',
  viewBox: '0 0 22 22',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9c0 1.78.557 3.52 1.601 5a9.408 9.408 0 0 0 4.264 3.315 9.992 9.992 0 0 0 5.488.512 9.675 9.675 0 0 0 4.865-2.463 8.865 8.865 0 0 0 2.6-4.608 8.562 8.562 0 0 0-.541-5.2 9.124 9.124 0 0 0-3.5-4.04A9.88 9.88 0 0 0 9.5 0C6.98 0 4.564.948 2.782 2.636 1.001 4.324 0 6.613 0 9Zm15.709 4.982L4.24 3.118c1.561-1.231 3.552-1.865 5.58-1.777 2.03.087 3.951.89 5.387 2.251 1.437 1.36 2.284 3.18 2.377 5.103.092 1.922-.577 3.808-1.876 5.287Zm-11.475.907C2.591 13.567 1.568 11.68 1.39 9.646 1.212 7.61 1.893 5.59 3.284 4.03l11.461 10.858A8.42 8.42 0 0 1 9.49 16.71a8.419 8.419 0 0 1-5.256-1.821Z"
    />
  ),
});

export default AlertError;
