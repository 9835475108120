import { createIcon } from '@chakra-ui/react';

const DoubleRight = createIcon({
  displayName: 'DoubleRight',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.27 11.541a.766.766 0 0 1 0-1.082L5.728 7 2.27 3.541A.766.766 0 1 1 3.352 2.46l4 4a.766.766 0 0 1 0 1.082l-4 4a.766.766 0 0 1-1.082 0Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.27 11.541a.766.766 0 0 1 0-1.082L10.728 7 7.27 3.541A.766.766 0 1 1 8.352 2.46l4 4a.766.766 0 0 1 0 1.082l-4 4a.766.766 0 0 1-1.082 0Z"
    />,
  ],
});

export default DoubleRight;
