// eslint-disable-next-line import/no-extraneous-dependencies
import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: {
    body: {
      fontFamily: 'body',
      color: 'body_color',
      bg: 'body_bg_color',
      transitionProperty: 'background-color',
      transitionDuration: 'normal',
      lineHeight: 'base',
    },
    '.ace_editor': {
      fontFamily: 'mono',
    },
    'body .swagger-ui *': {
      fontFamily: 'body',
    },
    'body .swagger-ui pre, body .swagger-ui code': {
      fontFamily: 'mono',
    },
    'body .chakra-form__required-indicator': {
      color: 'positive',
    },
    '.css-usinoq': {
      color: 'primary.400',
    },
  },
};

export default styles;
