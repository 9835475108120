import { createIcon } from '@chakra-ui/react';

export const StreamWorker = createIcon({
  displayName: 'StreamWorkerIcon',
  viewBox: '0 0 14 14',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.67 10.48a.59.59 0 0 1 .041-.344.59.59 0 0 1 .54-.358h.033a.716.716 0 1 0 0-1.431h-.06a.59.59 0 0 1-.541-.358V7.96a.591.591 0 0 1 .118-.651l.021-.022a.715.715 0 0 0-.506-1.223.716.716 0 0 0-.507.21l-.021.022a.59.59 0 0 1-.652.118.59.59 0 0 1-.358-.54V5.84a.716.716 0 1 0-1.431 0v.06a.59.59 0 0 1-.358.541H8.96a.59.59 0 0 1-.651-.118l-.022-.021a.716.716 0 1 0-1.013 1.013l.022.021a.59.59 0 0 1 .118.652.59.59 0 0 1-.54.386H6.84a.716.716 0 1 0 0 1.432h.06a.59.59 0 0 1 .541.358.59.59 0 0 1-.118.652l-.021.021a.715.715 0 0 0 .506 1.223.716.716 0 0 0 .507-.21l.021-.022a.59.59 0 0 1 .652-.118.59.59 0 0 1 .386.54v.033a.716.716 0 1 0 1.432 0v-.06a.59.59 0 0 1 .358-.541.591.591 0 0 1 .652.118l.021.021a.714.714 0 0 0 1.013 0 .718.718 0 0 0 0-1.013l-.022-.021a.59.59 0 0 1-.158-.308Zm-1.269-1.418a1.339 1.339 0 1 1-2.677 0 1.339 1.339 0 0 1 2.677 0ZM4.341 1.825c-.915-.165-1.912-.046-3.121.701a.656.656 0 0 1-.69-1.117C2 .501 3.324.308 4.574.533c1.208.218 2.306.823 3.322 1.395 1.879 1.057 3.89.703 4.71-.36a.656.656 0 0 1 1.039.8c-1.35 1.751-4.156 1.962-6.392.704C6.21 2.486 5.299 1.998 4.34 1.825ZM3.82 5.004c-.703-.01-1.549.23-2.549.987a.656.656 0 1 1-.792-1.047c1.187-.898 2.31-1.267 3.358-1.253 1.04.014 1.928.405 2.652.888a.656.656 0 1 1-.728 1.092c-.588-.392-1.232-.658-1.942-.667ZM3.168 8.436c-.603.124-1.263.471-1.847 1.013a.656.656 0 1 1-.892-.962c.729-.677 1.6-1.157 2.474-1.337.879-.18 1.804-.064 2.542.524a.656.656 0 0 1-.818 1.027c-.36-.287-.86-.389-1.459-.265Z"
    />
  ),
});

export default StreamWorker;
