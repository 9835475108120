export const memberInfoQuery = 'FOR doc IN _members RETURN doc';
export const locationUpdateQuery = (values: string) =>
  `FOR x IN _members UPDATE ${values} IN _members`;
export const alertsByUserNameQuery = `FOR x IN @@collection FILTER x.user == @username return x`;

export enum QueryType {
  C8QL = 'c8ql',
  SQL = 'sql',
}

export enum QueryMode {
  AQL = 'aql',
  SQL = 'sql',
}
