import { RouteLabels } from 'routing-details';
import { Features } from '../types/tenants';

export enum RouteTypes {
  Open = 'open',
  Protected = 'protected',
}

export enum OpenAppRoutes {
  Root = '/',
  Login = '/login',
  Maintenance = '/maintenance',
}

export type MapRouteLabelsToFeatureGateKeys =
  | RouteLabels.SearchViews
  | RouteLabels.Streams
  | RouteLabels.StreamWorkers
  | RouteLabels.Graphs
  | RouteLabels.GeoFabrics
  | RouteLabels.QueryWorkers
  | RouteLabels.Functions;

export const MapRouteLabelsToFeatureGate: Record<MapRouteLabelsToFeatureGateKeys, keyof Features> =
  {
    [RouteLabels.SearchViews]: 'SEARCH',
    [RouteLabels.Streams]: 'STREAMS',
    [RouteLabels.StreamWorkers]: 'CEP',
    [RouteLabels.Graphs]: 'GRAPHS',
    [RouteLabels.GeoFabrics]: 'GEO_FABRICS',
    [RouteLabels.QueryWorkers]: 'DOCS',
    [RouteLabels.Functions]: 'COMPUTE_FAAS',
    [RouteLabels.Functions]: 'COMPUTE_AKAM',
  };
