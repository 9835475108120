import { createIcon } from '@chakra-ui/react';

const DropdownIcon = createIcon({
  displayName: 'DropdownIcon',
  viewBox: '0 0 14 14',
  path: [
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9375 5.25L7 0.875L3.0625 5.25H10.9375Z" />,
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9375 8.75L7 13.125L3.0625 8.75H10.9375Z" />,
  ],
});

export default DropdownIcon;
