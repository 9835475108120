// eslint-disable-next-line import/prefer-default-export
export enum Mode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum ExportDocType {
  CSV = 'CSV',
  JSON = 'JSON',
}

export enum Tenants {
  MM = '_mm',
  Demo = 'demo',
}

export enum Fabrics {
  System = '_system',
}

export enum Users {
  Root = 'root',
}

export enum Action {
  RENAME = 'Rename',
  DELETE = 'Delete',
}

export enum AlertStatus {
  SUCCESS = 'Success!',
  ERROR = 'Error!',
  WARNING = 'Warning!',
  CRITICAL_ERROR = 'Critical Error!',
}

export enum PermissionType {
  READ_WRITE = 'rw',
  READ_ONLY = 'ro',
  NO_ACCESS = 'none',
  USE_DEFAULT = 'undefined',
}

export enum StandardPlans {
  ENTERPRISE = 'Enterprise',
  FREE = 'Free',
  METERED = 'Metered',
  PLAYGROUND = 'PLAYGROUND',
  SCALE = 'SCALE',
}

export enum Attributions {
  Macrometa = 'Macrometa',
  CoxEdge = 'Cox-Edge',
}

export enum SystemCollections {
  C8Federation = '_c8federation',
  GUI = '_gui',
  ALERTS = '_alerts',
}

export enum DocumentKeys {
  EnableTenantUserUUID = 'enableTenantUserUUID',
  EnableGlobalUrl = 'enableGlobalUrl',
  EnableLimits = 'enableLimits',
  EnableRateLimits = 'enableRateLimits',
  EnableSingleRegionFabric = 'enableSingleRegionFabric',
  EnableAvantGardeFlow = 'enableAvantGardeFlow',
}

export enum JSONEditorModes {
  View = 'view',
  Tree = 'tree',
  Code = 'code',
}

export enum DataSizes {
  KB = 1024,
  MB = 1048576,
  GB = 1073741824,
}

export enum SetInterval {
  ThreeMs = 3000,
}
