import { PositionProps } from '@chakra-ui/react';
import { ParseError, SchemaValidationError } from 'jsoneditor';
import { UseQueryResult } from 'react-query';
import { Column } from 'react-table';
import { DeleteModalProps } from './appDeleteModal';

export type Header = Record<string, string>;

// FIXME: proper file to keep component typeu
export type TableColumn = Column<Record<string, any>>;

export type DropDownOptions = {
  value: string;
  label: string;
};

export type ApiResponse<T> = {
  readonly data: {
    readonly result: readonly T[];
  };
};

export interface CommonDeleteProps extends Omit<DeleteModalProps, 'onConfirmDelete' | 'onClose'> {
  handleCloseModal: () => void;
  refetch: () => Promise<UseQueryResult>;
}

export type JSONEditorProps = {
  /**
   * json data in the editor
   */
  json: any;
  /**
   * if true, editor contents cannot be changed
   */
  readonly?: boolean;
  /**
   * fires when the value in editor changes
   */
  onChangeText: (json: string) => unknown;
  /**
   * fires when validation of the content in editor fails & returns the error info
   */
  onValidationError: (errors: ReadonlyArray<SchemaValidationError | ParseError>) => unknown;
};

export type UserInfo = {
  jwt: string;
  tenant: string;
  username: string;
};

export type LoginResponse = {
  data: UserInfo;
};

export type BreadcrumbItem = {
  name: string;
  path?: string;
};

export type AppBreadCrumbProps = {
  data: BreadcrumbItem[];
};

export type FrontendConfig = {
  publishableKey: string;
  sentryDSN: string;
  posthogAPIKey: string;
  posthogServerURL: string;
  auth: string;
  isSaaS: boolean;
  isProduction: boolean;
};

export type AppSpinnerProps = {
  isTransparent?: boolean;
  loaderPosition?: PositionProps['position'];
};

export enum SubDomain {
  Macrometa = 'macrometa.io',
}

export enum CookieKey {
  AccessToken = 'access_token',
  HostURL = 'hostURL',
  TemporaryFabric = 'temporaryFabric',
}

export enum AdminUserNames {
  mm = '_mm.root',
  demo = 'demo.root',
}
