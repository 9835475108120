export enum AuthorisedFeature {
  PLANS = 'Plans',
  BILLING = 'Billing',
  AUTHENTICATION = 'Authentication',
  API_KEYS = 'API Keys',
  TENANT = 'Tenant',
  IMPORT_EXPORT = 'Import Export',
  EDGE_LOCATIONS = 'Edge Locations',
}

export enum Feature {
  GEO_FABRICS = 'GeoFabrics',
  ENVIRONMENTS = 'Environments',
  GRAPHS = 'Graphs',
  STREAMS = 'Streams',
  STREAM_IO_CONNECTORS = 'Stream IO Connectors',
  DYNAMO = 'Dynamo',
  SEARCH = 'Search',
  CEP = 'CEP',
  KV = 'Key Value',
  USERS = 'Users',
  COMPUTE_CAAS = 'Container',
  COMPUTE_AKAM = 'Function',
  COMPUTE_FAAS = 'Function',
  DOCS = 'DOCS',
  STREAM_APPS = 'Stream Workers',
  SQL = 'SQL',
  REDIS = 'Redis',
}

export enum DocFeature {
  DOCUMENTS = 'Documents',
  COLLECTIONS = 'Collections',
  QUERY = 'Query',
  QUERY_WORKERS = 'Query Workers',
  INDEXES = 'Indexes',
  TRANSACTIONS = 'Transactions',
}

export enum UserApi {
  MODIFYUSER = 'ModifyUser',
  FETCHUSER = 'FetchUser',
}
