import { Select, SelectProps, Icon } from '@chakra-ui/react';
import DropdownIcon from '../assets/icons/Dropdown';
import { DropDownOptions } from '../types/commonTypes';

type Props = {
  options: DropDownOptions[];
};

function Dropdown({
  options,
  mx = 0,
  my = 0,
  size = 'md',
  fontSize = 'md',
  iconColor = 'primary.400',
  ...props
}: Props & SelectProps) {
  return (
    <Select
      variant="outline"
      size={size}
      _hover={{
        bg: 'primary.50',
      }}
      focusBorderColor="primary.400"
      mx={mx}
      my={my}
      icon={<Icon as={DropdownIcon} fill={iconColor} fontSize="sm" />}
      fontSize={fontSize}
      fontWeight="normal"
      colorScheme="neutral"
      // FIXME: remove spread operator
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}

export default Dropdown;
