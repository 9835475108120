import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Select,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { commonDynamoAPI } from '../../apis/collections';
import { DynamoAttributeTypes, DynamoHeaders, KeySchemaType } from '../../constants/collections';
import AppModal from '../../elements/AppModal';
import { useToast } from '../../providers/toastContext';
import {
  AttributeDefinitions,
  CreateDynamoIndex,
  CreateDynamoIndexModalProps,
  KeySchema,
  SecondaryIndex,
} from '../../types/collections';
import { createDynamoIndexSchema } from '../../validation/collection';

function CreateDynamoIndexModal({
  isOpen,
  toggleModal,
  tableName,
  refetch,
}: CreateDynamoIndexModalProps) {
  const { throwAppError } = useToast();

  const initialValues = {
    indexName: '',
    indexType: 'GSI',
    partitionKey: '',
    partitionKeyType: DynamoAttributeTypes.STRING,
    sortingKey: '',
    sortingKeyType: DynamoAttributeTypes.STRING,
  };

  const onClose = () => {
    toggleModal();
  };

  const { mutate: createDynamoIndexMutation } = useMutation(
    ({ body }: { body: CreateDynamoIndex }) => commonDynamoAPI(body, DynamoHeaders.UpdateTable),
    {
      onError: (err) => throwAppError(err),
      onSuccess: () => {
        onClose();
        refetch();
      },
    },
  );

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const { indexName, partitionKey, partitionKeyType, sortingKey, sortingKeyType } = values;
      const attributeDefinitions: AttributeDefinitions[] = [];
      let gis = {} as SecondaryIndex;
      const projectionType = { ProjectionType: 'ALL' };
      const gisKeySchema: KeySchema[] = [];

      attributeDefinitions.push({
        AttributeName: partitionKey,
        AttributeType: partitionKeyType,
      });
      gisKeySchema.push({
        AttributeName: partitionKey,
        KeyType: KeySchemaType.HASH,
      });
      if (sortingKey !== '') {
        attributeDefinitions.push({
          AttributeName: sortingKey,
          AttributeType: sortingKeyType,
        });
        gisKeySchema.push({
          AttributeName: sortingKey,
          KeyType: KeySchemaType.RANGE,
        });
      }

      gis = {
        IndexName: indexName,
        KeySchema: gisKeySchema,
        Projection: projectionType,
      };

      const body: CreateDynamoIndex = {
        AttributeDefinitions: attributeDefinitions,
        GlobalSecondaryIndexUpdates: [{ Create: gis }],
        TableName: tableName,
      };

      createDynamoIndexMutation({ body });
    },
    validationSchema: createDynamoIndexSchema,
  });

  const errorTextForIndexName = formik.touched.indexName && formik.errors.indexName;
  const errorTextForPartitionKey = formik.touched.partitionKey && formik.errors.partitionKey;

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      onClickPrimary={formik.handleSubmit}
      modalTitle="New Global Secondary Index"
      primaryText="Create"
      closeText="Cancel"
      formSubmitId="create-dynamo-index"
      size="2xl"
      isPrimaryDisabled={!(formik.dirty && formik.isValid)}
    >
      <form id="create-dynamo-index">
        <Text fontWeight="bold" fontSize="lg">
          Global Secondary Index
        </Text>
        <HStack py="3">
          <FormControl w="50%" isRequired isInvalid={!!errorTextForIndexName}>
            <FormLabel htmlFor="indexName" fontSize="md" m="0">
              Index Name
            </FormLabel>
            <Input
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="indexName"
              size="sm"
              width="98%"
              pr="4"
              borderRadius="base"
              data-cy="indexName"
            />
            {errorTextForIndexName && (
              <FormErrorMessage position="absolute" mt="1">
                {errorTextForIndexName.replace('indexName', 'Index name')}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl w="50%">
            <FormLabel htmlFor="indexType" fontSize="md" m="0">
              Index Type
            </FormLabel>
            <Select
              onChange={formik.handleChange}
              name="indexType"
              size="sm"
              width="98%"
              borderRadius="base"
            >
              <option value="GSI">Global Secondary Index(GSI)</option>
            </Select>
          </FormControl>
        </HStack>
        <HStack py="3">
          <FormControl w="50%" isRequired isInvalid={!!errorTextForPartitionKey}>
            <FormLabel htmlFor="partitionKey" fontSize="md" m="0">
              Partition Key
            </FormLabel>
            <Input
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="partitionKey"
              size="sm"
              width="98%"
              pr="4"
              borderRadius="base"
              data-cy="partitionKey"
            />
            {errorTextForPartitionKey && (
              <FormErrorMessage position="absolute" mt="1">
                {errorTextForPartitionKey}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl w="50%">
            <FormLabel htmlFor="partitionKeyType" fontSize="md" m="0">
              Partition Key Type
            </FormLabel>
            <Select
              onChange={formik.handleChange}
              name="partitionKeyType"
              size="sm"
              width="98%"
              borderRadius="base"
            >
              <option value={DynamoAttributeTypes.STRING}>String</option>
              <option value={DynamoAttributeTypes.NUMBER}>Number</option>
              <option value={DynamoAttributeTypes.BINARY}>Binary</option>
            </Select>
          </FormControl>
        </HStack>
        <HStack py="3">
          <FormControl w="50%">
            <FormLabel htmlFor="sortingKey" fontSize="md" m="0">
              Sorting Key (Optional)
            </FormLabel>
            <Input
              type="text"
              onChange={formik.handleChange}
              name="sortingKey"
              size="sm"
              width="98%"
              pr="4"
              borderRadius="base"
            />
          </FormControl>
          <FormControl w="50%">
            <FormLabel htmlFor="sortingKeyType" fontSize="md" m="0">
              Sorting Key Type (Optional)
            </FormLabel>
            <Select
              onChange={formik.handleChange}
              name="sortingKeyType"
              size="sm"
              width="98%"
              borderRadius="base"
            >
              <option value={DynamoAttributeTypes.STRING}>String</option>
              <option value={DynamoAttributeTypes.NUMBER}>Number</option>
              <option value={DynamoAttributeTypes.BINARY}>Binary</option>
            </Select>
          </FormControl>
        </HStack>
      </form>
    </AppModal>
  );
}

export default CreateDynamoIndexModal;
