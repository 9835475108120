import { HeaderFooterProps } from '../../types/table';
import TablePagination from '../table/TablePagination';

function CollectionFooter(props: HeaderFooterProps) {
  const {
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    pageIndex,
    pageCount,
    pageSize,
    totalRecordsCount,
    hidePagination,
    handleLimits,
    gotoPage,
  } = props;
  return (
    <TablePagination
      canNextPage={canNextPage}
      canPreviousPage={canPreviousPage}
      nextPage={nextPage}
      previousPage={previousPage}
      pageIndex={pageIndex}
      pageCount={pageCount}
      pageSize={pageSize}
      totalRecordsCount={totalRecordsCount}
      hidePagination={hidePagination}
      handleLimits={handleLimits}
      gotoPage={gotoPage}
    />
  );
}

export default CollectionFooter;
