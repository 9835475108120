import Button from './button';
import Checkbox from './checkbox';
import FormLabel from './formLabel';
import Input from './input';
import Link from './link';
import Radio from './radio';
import Select from './select';
import Switch from './switch';
import Textarea from './textarea';
import Modal from './modal';
import Drawer from './drawer';

export default {
  Button,
  Checkbox,
  FormLabel,
  Input,
  Link,
  Radio,
  Select,
  Switch,
  Textarea,
  Modal,
  Drawer,
};
