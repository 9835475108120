import { createIcon } from '@chakra-ui/react';

const Graph = createIcon({
  displayName: 'GraphIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.325 3.47a.904.904 0 1 0 0-1.808.904.904 0 0 0 0 1.809Zm2.217-.903a2.217 2.217 0 1 1-4.434 0 2.217 2.217 0 0 1 4.434 0ZM10.88 12.338a1.458 1.458 0 1 0 0-2.917 1.458 1.458 0 0 0 0 2.917Zm2.77-1.459a2.77 2.77 0 1 1-5.542 0 2.77 2.77 0 0 1 5.542 0ZM3.675 9.012a2.012 2.012 0 1 0 0-4.025 2.012 2.012 0 0 0 0 4.025ZM7 7A3.325 3.325 0 1 1 .35 7 3.325 3.325 0 0 1 7 7Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.118 3.372c.167.25.1.59-.152.758L6.472 5.793a.547.547 0 1 1-.607-.91L8.36 3.22c.251-.168.591-.1.759.152ZM5.895 8.195a.547.547 0 0 1 .74-.222l2.702 1.455a.547.547 0 0 1-.518.963L6.117 8.936a.547.547 0 0 1-.222-.74Z"
    />,
  ],
});

export default Graph;
