import { createIcon } from '@chakra-ui/react';

const Account = createIcon({
  displayName: 'AccountIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.962 5.32a1.424 1.424 0 1 0 0 2.847 1.424 1.424 0 0 0 0-2.848ZM4.138 6.742a2.824 2.824 0 1 1 5.648 0 2.824 2.824 0 0 1-5.648 0ZM4.387 10.836a4.948 4.948 0 0 1 6.974 1.958.7.7 0 1 1-1.245.642 3.548 3.548 0 0 0-6.307 0 .7.7 0 0 1-1.245-.642 4.948 4.948 0 0 1 1.823-1.958Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.515 1.145a6.6 6.6 0 0 1 8.723 9.62.7.7 0 0 1-1.111-.851 5.2 5.2 0 1 0-8.254 0 .7.7 0 1 1-1.11.852 6.6 6.6 0 0 1 1.752-9.621Z"
    />,
  ],
});

export default Account;
