import { createIcon } from '@chakra-ui/react';

const Stream = createIcon({
  displayName: 'StreamIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.341 1.825c-.915-.165-1.912-.046-3.121.701a.656.656 0 0 1-.69-1.117C2 .501 3.324.308 4.574.533c1.208.218 2.306.823 3.322 1.395 1.879 1.057 3.89.703 4.71-.36a.656.656 0 0 1 1.039.8c-1.35 1.751-4.156 1.962-6.392.704C6.21 2.486 5.299 1.998 4.34 1.825ZM4.341 5.325c-.915-.165-1.912-.046-3.121.701a.656.656 0 0 1-.69-1.117C2 4.001 3.324 3.808 4.574 4.033c1.208.218 2.306.823 3.322 1.395 1.879 1.057 3.89.703 4.71-.36a.656.656 0 0 1 1.039.8c-1.35 1.751-4.156 1.962-6.392.704C6.21 5.986 5.299 5.498 4.34 5.325ZM4.341 8.825c-.915-.165-1.912-.046-3.121.701a.656.656 0 0 1-.69-1.117C2 7.501 3.324 7.308 4.574 7.533c1.208.218 2.306.823 3.322 1.395 1.879 1.057 3.89.703 4.71-.36a.656.656 0 0 1 1.039.8c-1.35 1.751-4.156 1.962-6.392.704C6.21 9.486 5.299 8.998 4.34 8.825Z"
    />,
  ],
});

export default Stream;
