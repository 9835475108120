import { Flex, CloseButton, Box, useToast, Text } from '@chakra-ui/react';
import AlertCritical from '../assets/icons/AlertCritical';
import AlertError from '../assets/icons/AlertError';
import AlertWarning from '../assets/icons/AlertWarning';
import { AlertStatus } from '../constants/common';
import AppToasterProps from '../types/appToaster';
import { ToastContext } from './toastContext';

// While using the createStandaloneToast method theme is resetting to default theme
// and normal toast also not working
// that's why we implemented context based approach.
function ToastProvider({ children }: { children: React.ReactNode }) {
  const toast = useToast();

  const appToast = ({ alertStatus, alertDescription }: AppToasterProps) => {
    const alertIcon = () => {
      switch (alertStatus) {
        case AlertStatus.CRITICAL_ERROR:
          return <AlertCritical fill="red.500" mr="2" />;
        case AlertStatus.WARNING:
          return <AlertWarning fill="orange.500" mr="2" />;
        case AlertStatus.ERROR:
          return <AlertError fill="red.500" mr="2" />;
        // FIXME: Add Icon for Success
        default:
          return '';
      }
    };

    const fontColor = () => {
      switch (alertStatus) {
        case AlertStatus.CRITICAL_ERROR:
          return 'red.500';
        case AlertStatus.ERROR:
          return 'red.500';
        case AlertStatus.WARNING:
          return 'orange.500';
        case AlertStatus.SUCCESS:
          return 'green.500';
        // FIXME: Confirm fontColor for Success
        default:
          return '';
      }
    };

    const backgroundColor = () => {
      switch (alertStatus) {
        case AlertStatus.CRITICAL_ERROR:
          return 'red.50';
        case AlertStatus.WARNING:
          return 'orange.50';
        case AlertStatus.ERROR:
          return 'magenta.50';
        case AlertStatus.SUCCESS:
          return 'green.50';
        // FIXME: Confirm background color for Success
        default:
          return '';
      }
    };

    const id = `appToast-${Math.random() * 1000}`;

    toast({
      id,
      position: 'top-right',
      duration: 5000,
      render: () => (
        <Box w="96" backgroundColor={backgroundColor()} borderRadius="md" p="6">
          <Flex alignItems="center" mb="2">
            {alertIcon()}
            <Text color={fontColor()} fontWeight="bold">
              {alertStatus}
            </Text>
          </Flex>
          <Text color={fontColor()}>{alertDescription}</Text>
          <CloseButton
            top="6"
            w="2"
            h="2"
            position="absolute"
            color="neutral.300"
            right="6"
            onClick={() => toast.close(id)}
          />
        </Box>
      ),
    });
  };

  const throwAppError = (error: any) => {
    if (error && error.response && error.response.data) {
      appToast({
        alertStatus: AlertStatus.ERROR,
        alertDescription: error.response.data?.errorMessage
          ? error.response.data.errorMessage
          : error.response.data.message,
      });
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ToastContext.Provider value={{ appToast, throwAppError }}>{children}</ToastContext.Provider>
  );
}

export default ToastProvider;
