import { createIcon } from '@chakra-ui/react';

const DoubleLeft = createIcon({
  displayName: 'DoubleLeft',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7289 2.45862C12.0279 2.75762 12.0279 3.24238 11.7289 3.54138L8.27026 7L11.7289 10.4586C12.0279 10.7576 12.0279 11.2424 11.7289 11.5414C11.4299 11.8404 10.9451 11.8404 10.6461 11.5414L6.64612 7.54138C6.34713 7.24238 6.34713 6.75762 6.64612 6.45862L10.6461 2.45862C10.9451 2.15963 11.4299 2.15963 11.7289 2.45862Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72888 2.45862C7.02787 2.75762 7.02787 3.24238 6.72888 3.54138L3.27026 7L6.72888 10.4586C7.02787 10.7576 7.02787 11.2424 6.72888 11.5414C6.42988 11.8404 5.94512 11.8404 5.64612 11.5414L1.64612 7.54138C1.34713 7.24238 1.34713 6.75762 1.64612 6.45862L5.64612 2.45862C5.94512 2.15963 6.42988 2.15963 6.72888 2.45862Z"
    />,
  ],
});

export default DoubleLeft;
