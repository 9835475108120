import { createIcon } from '@chakra-ui/react';

const ChevronDown = createIcon({
  displayName: 'ChevronDownIcon',
  viewBox: '0 0 18 10',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7852 0.65228C16.2727 0.139717 15.4416 0.139717 14.9291 0.65228L9 6.58134L3.07094 0.65228C2.55838 0.139717 1.72735 0.139717 1.21479 0.65228C0.702221 1.16484 0.702221 1.99587 1.21479 2.50844L8.07193 9.36558C8.58449 9.87814 9.41552 9.87814 9.92808 9.36558L16.7852 2.50844C17.2978 1.99587 17.2978 1.16484 16.7852 0.65228Z"
    />
  ),
});

export default ChevronDown;
