import { Box } from '@chakra-ui/react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useConfig } from '../../providers/configContext';
import { showBanner } from '../../utils/helper';
import AppSpinner from '../AppSpinner';
import ErrorBoundary from '../ErrorBoundary';

export default function PageContainer() {
  const { tenantDetails, isAvantGardeFlowEnabled } = useConfig();

  return (
    <Box
      ml={240}
      w="100%"
      h={showBanner(tenantDetails, isAvantGardeFlowEnabled) ? '95vh' : '100vh'}
      overflowX="hidden"
    >
      <ErrorBoundary>
        <Suspense fallback={<AppSpinner />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
}
