import { Button, Flex, HStack, SimpleGrid, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DeleteModalProps } from '../types/appDeleteModal';
import AppModal from './AppModal';

function AppDeleteModal({
  isOpen,
  onClose,
  confirmationMessage,
  modalTitle,
  itemLabel,
  itemValue,
  onConfirmDelete,
  children,
  primaryText,
}: DeleteModalProps) {
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    setShowConfirm(false);
  }, [isOpen]);

  const deleteConfirmation = () => (
    <Flex
      data-cy="confirmationModal"
      bg="red.50"
      justify="space-between"
      align="center"
      p={3}
      mt={5}
    >
      <Text fontWeight="bold" color="red.600">
        {confirmationMessage}
      </Text>
      <HStack>
        <Button variant="neutral" onClick={() => setShowConfirm(false)}>
          No
        </Button>
        <Button
          variant="danger"
          onClick={() =>
            typeof onConfirmDelete === 'function' ? (onConfirmDelete(), onClose()) : onClose()
          }
        >
          Yes
        </Button>
      </HStack>
    </Flex>
  );

  const modalFooter = () => (
    <>
      <Flex justifyContent="space-between">
        <Button variant="neutral" onClick={onClose}>
          Close
        </Button>
        <Button variant="danger" onClick={() => setShowConfirm(true)}>
          {primaryText}
        </Button>
      </Flex>
      {showConfirm && deleteConfirmation()}
    </>
  );

  return (
    <AppModal
      modalTitle={modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      modalFooter={modalFooter()}
    >
      {children ?? (
        <SimpleGrid columns={2}>
          {itemLabel && <Text color="body_color">{itemLabel} :</Text>}
          <Text color="body_color">{itemValue}</Text>
        </SimpleGrid>
      )}
    </AppModal>
  );
}

export default AppDeleteModal;

AppDeleteModal.defaultProps = {
  confirmationMessage: 'Really delete?',
  children: null,
  itemLabel: '',
  primaryText: 'Delete',
};
