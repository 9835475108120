import { createIcon } from '@chakra-ui/react';

const Support = createIcon({
  displayName: 'SupportIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.525a5.475 5.475 0 1 0 0 10.95 5.475 5.475 0 0 0 0-10.95ZM.475 7a6.525 6.525 0 1 1 13.05 0A6.525 6.525 0 0 1 .475 7Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5.125a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75ZM4.075 7a2.925 2.925 0 1 1 5.85 0 2.925 2.925 0 0 1-5.85 0Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.387 2.387a.525.525 0 0 1 .742 0L5.673 4.93a.525.525 0 1 1-.742.742L2.387 3.13a.525.525 0 0 1 0-.742ZM8.327 8.327a.525.525 0 0 1 .742 0l2.544 2.544a.525.525 0 0 1-.742.742L8.327 9.07a.525.525 0 0 1 0-.742ZM11.613 2.387a.525.525 0 0 1 0 .742L9.07 5.673a.525.525 0 1 1-.742-.742l2.544-2.544a.525.525 0 0 1 .742 0Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.187 2.813a.525.525 0 0 1 0 .742L9.07 5.673a.525.525 0 1 1-.742-.742l2.118-2.118a.525.525 0 0 1 .742 0ZM5.673 8.327a.525.525 0 0 1 0 .742L3.13 11.613a.525.525 0 1 1-.742-.742L4.93 8.327a.525.525 0 0 1 .742 0Z"
    />,
  ],
});

export default Support;
